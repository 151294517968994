import {
    CREATE_PATIENT,
    DELETE_PATIENT,
    SET_PATIENT,
    SET_REPEAT,
    SHOW_PATIENTS,
    UPDATE_PATIENT,
} from "../type";

const initialState = {
    patients: [],
    patient: {},
    total: 0,
    male: 0,
    female: 0,
    createdPatient: {},
    isRepeat: false,
};

const patient = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_PATIENTS:
            return {
                ...state,
                patients: action.payload.data,
                total: action.payload.total,
                male: action.payload.male,
                female: action.payload.female,
            };
        case CREATE_PATIENT:
            return {
                ...state,
                patients: [action.payload, ...state.patients],
                total: state.total + 1,
                createdPatient: action.payload,
            };
        case DELETE_PATIENT:
            return {
                ...state,
                patients: state.patients.filter(
                    (patient) => patient.id !== action.payload
                ),
                total: state.total - 1,
            };
        case SET_PATIENT:
            return {
                ...state,
                patient: action.payload,
            };
        case UPDATE_PATIENT:
            if (JSON.stringify(state.patient) !== JSON.stringify(action.payload)) {
                let clone = [...state.patients];
                let index = clone.findIndex(
                    (patient) => patient.id === action.payload.id
                );
                clone[index] = action.payload;
                return {
                    ...state,
                    patients: clone,
                    patient: action.payload,
                };
            } else {
                return state;
            }
        case SET_REPEAT:
            return {
                ...state,
                isRepeat: action.payload,
            };
        default:
            return state;
    }
};

export default patient;
