import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'reactstrap'
import { useDispatch } from 'react-redux'

import CustomTextArea from 'components/Inputs/CustomTextArea'
import CustomModal from 'components/Modal/CustomModal'

import { updatePrepRemark } from 'store/actions'


const TruenatPrepRemarkUpdate = ({ isOpen, toggle, editData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ shouldUnregister: true })
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(()=> {
    reset({
        remark: editData?.remark
    })
  },[editData?.remark, reset])
  const onSubmit = useCallback(
    async (data) => {
      setLoading(true)
      await dispatch(
        updatePrepRemark(
          editData?.id,
          {
            remark: data?.remark
          }
        )
      )
      setLoading(false)
      toggle()
    },
    [dispatch, editData?.id, toggle]
  )

  return (
    <CustomModal
      isOpen={isOpen}
      title={`Clinic Visit Remark`}
      onClick={toggle}
      style={{ maxWidth: '60%' }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col md={6}>
              <CustomTextArea
                id="remark"
                label="Remark"
                register={{
                  ...register('remark'),
                }}
                placeholder="Write remark..."
                errors={errors}
              />
            </Col>
          </Row>
        </div>

        <div className="modal-footer d-flex justify-content-end">
            <Button color="success" disabled={loading} type="submit">
              {loading ? 'Loading' : 'Update'}
            </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

export default TruenatPrepRemarkUpdate
