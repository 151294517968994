import styles from "./Truenat.module.css";
import classnames from "classnames";

const TruenatHead = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th
          className={["fixed-cell left", styles.skyblue].join(" ")}
          rowSpan={3}
        >
          No
        </th>
        <th
          className={["fixed-cell left ", styles.skyblue].join(" ")}
          rowSpan={3}
        >
          Organization
        </th>
        <th className={["fixed-cell ", styles.skyblue].join(" ")} rowSpan={3}>
          Region/State
        </th>
        <th className={["fixed-cell ", styles.skyblue].join(" ")} rowSpan={3}>
          Township
        </th>
        <th className={["fixed-cell ", styles.skyblue].join(" ")} rowSpan={3}>
          Fiscal Year(FY)
        </th>
        <th className={["fixed-cell ", styles.skyblue].join(" ")} rowSpan={3}>
          Quarter
        </th>
        <th className={[styles.yellow, styles.width].join(" ")}>
          TN-DNA extraction (Denominator){" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where stock_id 2 in PREP count</span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")}>
          TN-DNA extraction (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where stock_id 2 and result Isolation failure or error in PREP count</span>
          </i>
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")} colSpan={4}>
          TN-MTB Detected (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result VL,L,M,H in PLUS count</span>
          </i>
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")}>
          TN-MTB Not detected (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result MTB-Not Detected in PLUS count</span>
          </i>
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")}>
          TN-MTB Unsuccessful (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result Invalid in PLUS count</span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")} colSpan={4}>
          TN-MTB-RIF detected (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result MTB-Detected, RR (+) in RIF count</span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")}>
          TN-MTB-RIF Not detected (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result MTB-Detected, RR (-) in RIF count</span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")}>
          TN-MTB-RIF Indeterminate (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result MTB-Detected, RR-Indeterminate in RIF count</span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")}>
          TN-MTB-RIF Unsuccessful (Numerator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result Invalid in RIF count</span>
          </i>
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")} colSpan={3}>
          TN-MTB & Smear positive (Denominator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result VL,L,M,H in PLUS and smear,gxp done</span>
          </i>
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")} colSpan={4}>
          TN-MTB detected  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result VL,L,M,H in PLUS and smear,gxp done,positive,negative</span>
          </i>
        </th>

        <th className={[styles.yellow, styles.width].join(" ")} colSpan={2}>
          TN-MTB-RIF & GXP RR positive (Denominator)  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>where result MTB-Detected, RR (+) in RIF, truenat, gxp done</span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")} colSpan={2}>
          TN-MTB-Rif detected  <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>gxp positive, negative</span>
          </i>
        </th>
      </tr>
      <tr>
        <th className={styles.yellow} rowSpan={2}>
          Total no. of DNA extractions performed (Truenat MTB Plus and MTB-RIF
          tests)
        </th>
        <th className={styles.yellow} rowSpan={2}>
          No. of specimens which DNA extraction was unsuccessful
        </th>
        <th className={styles.skyblue} colSpan={4}>
          No. of MTBC detected by Truenat MTB Plus
        </th>

        <th className={styles.skyblue} rowSpan={2}>
          No. of MTBC not detected by Truenat MTB Plus{" "}
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          No. of specimens with unsuccessful results (errors, invalid, no
          response)
        </th>
        <th className={styles.yellow} colSpan={4}>
          No.of rifampicin resistance detected by Truenat MTB-RIF
        </th>
        <th className={styles.yellow} rowSpan={2}>
          No. of rifampicin resistance not detected by Truenat MTB-RIF
        </th>
        <th className={styles.yellow} rowSpan={2}>
          No. of rifampicin indeterminate by Truenat MTB-RIF
        </th>
        <th className={styles.yellow} rowSpan={2}>
          No. of specimens with unsuccessful results tested by Truenat MTB-RIF
          (errors, invalid, no response)
        </th>
        <th className={styles.skyblue} colSpan={3}>
          Total no. of specimens tested by Truenat MTB Plus & Smear/GXP among
          Truenat MTB detected
        </th>
        <th className={styles.skyblue} colSpan={2}>
          Smear result among Truenat MTB detected
        </th>
        <th className={styles.skyblue} colSpan={2}>
          GXP result among Truenet MTB detected
        </th>
        <th className={styles.yellow} colSpan={2}>
          Total no. of specimens tested by TrueNat MTB-RIF & GXP among TrueNat
          MTB-Rif detected
        </th>
        <th className={styles.yellow} colSpan={2}>
          GXP result among Truenet MTB-Rif detected
        </th>
      </tr>
      <tr>
        <th className={styles.skyblue}> {"< 15 female"}</th>
        <th className={styles.skyblue}>{">+ 15 female"}</th>
        <th className={styles.skyblue}> {"< 15 male"}</th>
        <th className={styles.skyblue}>{">+ 15 male"}</th>
        <th className={styles.yellow}> {"< 15 female"}</th>
        <th className={styles.yellow}>{">+ 15 female"}</th>
        <th className={styles.yellow}> {"< 15 male"}</th>
        <th className={styles.yellow}>{">+ 15 male"}</th>
        <th className={styles.skyblue}>Truenat</th>
        <th className={styles.skyblue}>Smear</th>
        <th className={styles.skyblue}>GXP</th>
        <th className={styles.skyblue}>Smear Positive</th>
        <th className={styles.skyblue}>Smear Negative</th>
        <th className={styles.skyblue}>GXP (positive )</th>
        <th className={styles.skyblue}>GXP (negative)</th>
        <th className={styles.yellow}>Truenat</th>
        <th className={styles.yellow}>GXP</th>
        <th className={styles.yellow}>GXP PR (positive )</th>
        <th className={styles.yellow}>GXP PR (negative)</th>
      </tr>
    </thead>
  );
};
export default TruenatHead;
