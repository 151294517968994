import React, { useState, useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BackBtn from "utils/backBtn";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPatients } from "store/actions";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import CustomAlert from "components/Alert/CustomAlert";
import { deletePatient } from "store/actions";
import { recoverPatient } from "store/actions";

const TrashPatientList = () => {
  const router = useHistory();

  const [alert, setAlert] = useState(null);

  const dispatch = useDispatch();
  const { status, patient } = useSelector((state) => state);
  const { patients, total } = patient;

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.status = 0;
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getPatients(query));
  }, [dispatch, router.location.search]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deletePatient(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const handleRecover = async (id) => {
    await dispatch(recoverPatient(id));
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Trash Patient List</h3>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Lab No
                </th>
                <th className="fixed-cell left" scope="col">
                  Presumptive Code
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Sex</th>
                <th scope="col">Age</th>
                <th scope="col">Phone</th>
                <th scope="col">Address</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={patients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{index + 1}</td>
                <td>{patient.lab_no}</td>
                <td>{patient.presumptive_code}</td>
                <td>{patient.name}</td>
                <td>{patient.sex}</td>
                <td>{patient.age}</td>
                <td>{patient.phone}</td>
                <td>{patient.address}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => handleRecover(patient.id)}
                  >
                    Recover
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => handleDelete(patient.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default TrashPatientList;
