import CustomTextArea from 'components/Inputs/CustomTextArea'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Form,
    Row,
    Col,
    Container,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
} from 'reactstrap'
import { patientMiddleware } from "utils/middleware";
import { updatePatient } from 'store/actions'
import { UPDATE } from 'utils/middleware'

const Remark = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm()

    const [loading, setLoading] = useState(false)


    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
  const { role } = user;
    const { patient } = useSelector((state) => state);

    useEffect(() => {
        const { remark } =
            patient.patient;

        setValue("remark", remark);

    }, [patient.patient, setValue]);

    const onSubmit = async (data) => {
        setLoading(true);
        await dispatch(
            updatePatient(
                patient.patient.id,
                {
                    remark: data.remark
                },
                "remark"
            )
        );
        setLoading(false);
    };

    return (
        <Container className="mt-3" fluid>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader className="border-0">
                        <h3>Patient Remark</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <CustomTextArea
                                    id="remark"
                                    label="Remark"
                                    register={{
                                        ...register('remark'),
                                    }}
                                    placeholder="Enter Remark"
                                    errors={errors}
                                    isRequired={false}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                    {
              patientMiddleware(role, UPDATE) &&  <Button color="primary" size="sm" type="submit">
              {loading ? "Loading" : "Update"}
            </Button>
             }
                    </CardFooter>
                </Card>
            </Form>
        </Container>
    )
}

export default Remark