import {
  CREATE_HANDOUT,
  SET_HANDOUT,
  SHOW_HANDOUTS,
  UPDATE_HANDOUT,
} from '../type'

const initialState = {
  handouts: [],
  handout: {},
}

const handout = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HANDOUTS:
      return {
        ...state,
        handouts: action.payload,
      }
    case CREATE_HANDOUT:
      return {
        ...state,
        handouts: [action.payload, ...state.handouts],
      }
    case SET_HANDOUT:
      return {
        ...state,
        handout: action.payload,
      }
    case UPDATE_HANDOUT:
      if (JSON.stringify(state.handout) !== JSON.stringify(action.payload)) {
        let clone = [...state.handouts]
        let index = clone.findIndex((handout) => handout.id === action.payload.id)
        clone[index] = action.payload
        return {
          ...state,
          handouts: clone,
          handout: action.payload,
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default handout
