import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Card, CardHeader } from "reactstrap";
import BackBtn from "utils/backBtn";
import TruenatRemark from "./detailComponent/Remark";
import TruelabMTBPlus from "./detailComponent/TruelabMTBPlus";
import TruelabMTBRIF from "./detailComponent/TruelabMTBRIF";
import { useParams, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getTruenat } from "store/actions";
import { getPluses } from "store/actions";
import { getStocks } from "store/actions";
import { getRifs } from "store/actions";
import Specimen from "./detailComponent/Specimen";
import { getPreps } from "store/actions";
import Trueprep from "./detailComponent/Trueprep";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getRepeat } from "store/actions";

const TruenatDetail = () => {
  const { id } = useParams();
  const state = useLocation();
  const { patientId, index } = state.state;

  const [page, setPage] = useState("Specimen");

  const handleShowPage = () => {
    switch (page) {
      case "Specimen":
        return <Specimen />;
      case "Trueprep":
        return <Trueprep />;
      case "Turelab":
        return <TruelabMTBPlus />;
      case "Turelab_MTB_RIF":
        return <TruelabMTBRIF />;
      case "Remark":
        return <TruenatRemark />;
      default:
        return <Specimen />;
    }
  };

  const dispatch = useDispatch();
  const {
    prep,
    plus,
    truenat,
    status,
    patient: patientState,
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getTruenat(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getPreps(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getPluses(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getRifs(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getStocks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRepeat(patientId, index));
  }, [dispatch, patientId, index]);

  let plusResult = plus?.pluses[plus?.pluses.length - 1]?.result;

  const { patient } = truenat?.truenat;

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row className="px-3 pb-3">
            <span>
              Patient - {`${patient?.name}  ( ${patient?.presumptive_code} )`}
            </span>
          </Row>
          <Row className="mb-2">
            <Col>
              <Button
                color={page === "Specimen" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Specimen")}
              >
                Specimen
              </Button>
              <Button
                color={page === "Trueprep" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Trueprep")}
              >
                Trueprep
              </Button>
              {prep?.preps[prep?.preps.length - 1]?.result ===
                "DNA isolated" && (
                  <>
                    {!patientState.isRepeat && (
                      <Button
                        color={
                          page === "Turelab" ? "success" : "primary"
                        }
                        size="lg"
                        onClick={() => setPage("Turelab")}
                      >
                        Truelab
                      </Button>
                    )}
                    {(
                      plusResult === "MTB-Detected (VL)" ||
                      plusResult === "MTB-Detected (L)" ||
                      plusResult === "MTB-Detected (M)" ||
                      plusResult === "MTB-Detected (H)" || 
                      plusResult === "MTB-Detected" ||
                      (patientState.isRepeat &&
                        prep?.preps[prep?.preps.length - 1]?.result ===
                        "DNA isolated")) && (
                        <Button
                          color={page === "Turelab_MTB_RIF" ? "success" : "primary"}
                          size="lg"
                          onClick={() => setPage("Turelab_MTB_RIF")}
                        >
                          Truelab(MTB-RIF)
                        </Button>
                      )}
                  </>
                )}
              <Button
                color={page === "Remark" ? "success" : "primary"}
                size="lg"
                onClick={() => setPage("Remark")}
              >
                Remark
              </Button>
            </Col>
          </Row>
        </CardHeader>
        {handleShowPage()}
      </Card>
    </Container>
  );
};

export default TruenatDetail;
