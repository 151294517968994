import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { truelabMTBRIFResults } from "variables/options";
import CustomTable from "components/Table/Table";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomAlert from "components/Alert/CustomAlert";
import { deleteRif } from "store/actions";
import { createRif } from "store/actions";
import { useCallback } from "react";
import { useEffect } from "react";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { truenatMiddleware } from "utils/middleware";
import { DELETE } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { getPlusAndRifSolution } from "variables/plusAndRifErrors";
import { rifErrors } from "variables/options";
const TruelabMTBRIF = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { id } = useParams();

  const [alert, setAlert] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTruelabMTBRIFResult, setSelectedTruelabMTBRIFResult] =
    useState(null);
  const [selectedError, setSelectedError] = useState(null);

  const dispatch = useDispatch();
  const { rif, stock, status } = useSelector((state) => state);

  const stocks = stock.stocks.map((stock) => {
    return { value: stock.id, label: stock.name };
  });

  const defaultStock = stock.stocks.find((stock) => stock.id === 4);

  const setDefault = useCallback(() => {
    setValue("stock", { value: defaultStock?.id, label: defaultStock?.name });
    setSelectedItem({ value: defaultStock?.id, label: defaultStock?.name });
  }, [setValue, defaultStock]);

  useEffect(() => {
    setDefault();
  }, [setDefault]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      createRif({
        truenat_id: id,
        stock_id: data.stock.value,
        result: data.result.value,
        error:
          selectedTruelabMTBRIFResult?.value === "Error"
            ? data.error.value
            : null,
        remark: data.remark,
      })
    );
    setLoading(false);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deleteRif(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        label="You won't be able to recover this."
      />
    );
  };

  const formReset = useCallback(() => {
    setSelectedTruelabMTBRIFResult(null);
    setSelectedError(null);
    reset();
    setDefault();
  }, [reset, setSelectedTruelabMTBRIFResult, setSelectedError, setDefault]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  let rifResult = rif?.rifs[rif?.rifs.length - 1]?.result;

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Truelab(MTB-RIF)</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="stock"
                    label="Equipment"
                    rules={{ required: "Equipment is required!" }}
                    control={control}
                    options={stocks}
                    value={selectedItem}
                    setData={setSelectedItem}
                    placeholder="Equipment"
                    isDisabled={true}
                  />
                </Col>
              </Row>
              {(selectedTruelabMTBRIFResult?.value === "Invalid" ||
                selectedTruelabMTBRIFResult?.value === "Error") && selectedError?.value !== 'ERROR 8' && (
                  <>
                    <h3
                      style={{
                        color: "#2F76BA",
                      }}
                    >
                      SOLUTION
                    </h3>
                    <p
                      style={{
                        color: "#f00",
                        fontWeight: "bold",
                      }}
                    >
                      {getPlusAndRifSolution(selectedTruelabMTBRIFResult?.value)}
                    </p>
                  </>
                )}
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="result"
                    label="Result"
                    rules={{ required: "Result is required!" }}
                    control={control}
                    options={truelabMTBRIFResults}
                    value={selectedTruelabMTBRIFResult}
                    setData={setSelectedTruelabMTBRIFResult}
                    placeholder="Result"
                  />
                </Col>
              </Row>
              {selectedTruelabMTBRIFResult?.value === "Error" && (
                <Row>
                  <Col md={4}>
                    <CustomSelect
                      id="error"
                      label="Error"
                      rules={{ required: "Error is required!" }}
                      control={control}
                      options={rifErrors}
                      value={selectedError}
                      setData={setSelectedError}
                      placeholder="Error"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <CustomTextArea
                    id="remark"
                    label="Remark"
                    register={{
                      ...register("remark"),
                    }}
                    placeholder="Enter Remark"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
              </Row>
            </CardBody>
            {truenatMiddleware(role, CREATE) && (
              <CardFooter className="text-right">
                {rifResult !== "MTB-Detected, RR (-)" &&
                  rifResult !== "MTB-Detected, RR (+)" && (
                    <Button
                      color="primary"
                      size="sm"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Loading" : "Save"}
                    </Button>
                  )}
              </CardFooter>
            )}
          </Card>
        </Form>
        <Card>
          <CardBody>
            <CustomTable
              header={
                <tr>
                  <th className="fixed-cell left" scope="col">
                    ID
                  </th>
                  <th className="fixed-cell left" scope="col">
                    Truelab(MTB-RIF)
                  </th>
                  <th>Error</th>
                  <th>Remark</th>
                  {truenatMiddleware(role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
                </tr>
              }
              body={rif.rifs.map((rif, index) => (
                <tr key={rif.id}>
                  <td>{index + 1}</td>
                  <td>{rif.result}</td>
                  <td>{rif.error}</td>
                  <td>{rif.remark}</td>

                  {truenatMiddleware(role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(rif.id)}
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  )}
                </tr>
              ))}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default TruelabMTBRIF;
