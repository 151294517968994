import React, { useState, useEffect } from "react";
import { Container, Card, CardHeader, Row, Col, Button } from "reactstrap";
import BackBtn from "utils/backBtn";
import styles from "./report.module.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { currentDate } from "utils/currentDate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { useDispatch, useSelector } from "react-redux";
import { getStockReport } from "store/actions/report";

const StockStatusReport = () => {
  const router = useHistory();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { report } = useSelector((state) => state);
  const { loading } = useSelector((state) => state.status);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getStockReport(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const handleRefresh = () => {
    router.replace(router.location.pathname);
  };

  const { ID_1, ID_2, ID_3, ID_4, ID_5, ID_6} = report.stockReport;

  const data = [
    {
      item: ID_1?.name_1,
      unit: ID_1?.unit,
      opening_balance: ID_1?.opening_balance_1,
      received: ID_1?.received_1,
      issued: ID_1?.issued_1,
      damage: ID_1?.damage_1,
      expired: ID_1?.expired_1,
      closing_balance: ID_1?.closing_balance_1,
      stock_out_status: ID_1?.status,
    },
    {
      item: ID_2?.name_2,
      unit: ID_2?.unit,
      opening_balance: ID_2?.opening_balance_2,
      received: ID_2?.received_2,
      issued: ID_2?.issued_2,
      damage: ID_2?.damage_2,
      expired: ID_2?.expired_2,
      closing_balance: ID_2?.closing_balance_2,
      stock_out_status: ID_2?.status,
    },
    {
      item: ID_3?.name_3,
      unit: ID_3?.unit,
      opening_balance: ID_3?.opening_balance_3,
      received: ID_3?.received_3,
      issued: ID_3?.issued_3,
      damage: ID_3?.damage_3,
      expired: ID_3?.expired_3,
      closing_balance: ID_3?.closing_balance_3,
      stock_out_status: ID_3?.status,
    },
    {
      item: ID_4?.name_4,
      unit: ID_4?.unit,
      opening_balance: ID_4?.opening_balance_4,
      received: ID_4?.received_4,
      issued: ID_4?.issued_4,
      damage: ID_4?.damage_4,
      expired: ID_4?.expired_4,
      closing_balance: ID_4?.closing_balance_4,
      stock_out_status: ID_4?.status,
    },
    {
      item: ID_5?.name_5,
      unit: ID_5?.unit,
      opening_balance: ID_5?.opening_balance_5,
      received: ID_5?.received_5,
      issued: ID_5?.issued_5,
      damage: ID_5?.damage_5,
      expired: ID_5?.expired_5,
      closing_balance: ID_5?.closing_balance_5,
      stock_out_status: ID_5?.status,
    },
    {
      item: ID_6?.name_6,
      unit: ID_6?.unit,
      opening_balance: ID_6?.opening_balance_6,
      received: ID_6?.received_6,
      issued: ID_6?.issued_6,
      damage: ID_6?.damage_6,
      expired: ID_6?.expired_6,
      closing_balance: ID_6?.closing_balance_6,
      stock_out_status: ID_6?.status,
    },
  ];

  if (loading) {
    return <FullScreenLoading />;
  }
  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Stock Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={"Stock-Status-Report"}
                sheet="Stock-status"
                buttonText="Export"
              />
              <Button size="sm" color="primary" onClick={handleRefresh}>
                <i class="fas fa-redo"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={4}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                value={endDate}
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>
            <Col md={4}>
              <Button
                className="mt-3"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <div
          style={{
            overflow: "scroll",
          }}
        >
          <table id="table-to-xls" className={styles.styled_table}>
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Items</th>
                <th scope="col">Account Units</th>
                <th scope="col">Opening balance</th>
                <th scope="col">Received during quarter</th>
                <th scope="col">Issued during quarter</th>
                <th scope="col">Damage</th>
                <th scope="col">Expired</th>
                <th scope="col">Closing balance</th>
                <th scope="col">
                  Stock out status during quarter (Yes or No )
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row?.item}</td>
                    <td>{row?.unit}</td>
                    <td>{row?.opening_balance}</td>
                    <td>{row?.received}</td>
                    <td>{row?.issued}</td>
                    <td>{row?.damage}</td>
                    <td>{row?.expired}</td>
                    <td>{row?.closing_balance}</td>
                    <td>{row?.stock_out_status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>
    </Container>
  );
};

export default StockStatusReport;
