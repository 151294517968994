import axios from "axios";
import { removeToken } from "utils/cache";
import { setToken } from "utils/cache";
import { NotificationManager } from "react-notifications";
import { serverErrorMessage } from "utils/messages";

export const host = process.env.REACT_APP_API_ENDPOINT

export const setAccessToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setToken(token);
  } else {
    delete axios.defaults.headers.common["Authorization"];
    removeToken();
  }
};

const handleApiError = (error) => {
  const { status, data } = error.response;

  if (status === 400) {
    const obj = data.data;
    NotificationManager.error(obj[Object.keys(obj)[0]]);
  } else if (status === 401) {
    setAccessToken(null);
    NotificationManager.error(data.data.message);
  } else {
    NotificationManager.error(serverErrorMessage);
  }
  return;
};

const withErrorHandling =
  (apiFunc) =>
    async (...args) => {
      try {
        const result = await apiFunc(...args);
        return result;
      } catch (error) {
        handleApiError(error);
      }
    };

export const call = withErrorHandling(async (method, path, data) => {
  const response = await axios[method](`${host}/${path}`, data);
  return response.data;
});
