import { CREATE_PLUSES, DELETE_PLUSES, SHOW_PLUSES } from '../type'
  
  const initialState = {
    pluses: [],
  }
  
  const plus = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_PLUSES:
        return {
          ...state,
          pluses: action.payload,
        }
      case CREATE_PLUSES:
        return {
          ...state,
          pluses: [...state.pluses, action.payload],
        }
      case DELETE_PLUSES:
        return {
          ...state,
          pluses: state.pluses.filter(
            (plus) => plus.id !== action.payload,
          ),
        }
      default:
        return state
    }
  }
  
  export default plus
  