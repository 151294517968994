import { call } from '../../services/api'
import {
    CREATE_STOCK,
  DELETE_STOCK,
  SET_LOADING,
  SET_STOCK,
  SET_SUCCESS,
  SHOW_STOCKS,
  UPDATE_STOCK,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getStocks = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', 'stocks')
      const result = response.data

      dispatch({
        type: SHOW_STOCKS,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const getStock = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `stocks/${id}`)
      const result = response.data

      dispatch({
        type: SET_STOCK,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const createStock = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'stocks', data)
      const result = response.data

      dispatch({
        type: CREATE_STOCK,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Stock has been created successfully!')
    } catch (_) { }
  }
}

export const updateStock = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    })
    try {
      const response = await call('post', `stocks/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_STOCK,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Stock has been updated successfully!')
    } catch (_) { }
  }
}

export const deleteStock = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `stocks/${id}`)

      dispatch({ type: DELETE_STOCK, payload: id })
      NotificationManager.success('Stock has been deleted successfully!')
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}
