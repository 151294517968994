import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Card,
} from "reactstrap";
import { patientMiddleware } from "utils/middleware";
import CustomSelect from "components/Inputs/CustomSelect";
import { dones } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { sputumSmearResults } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updatePatient } from "store/actions";
import { UPDATE } from "utils/middleware";


const Smear = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [suptumDoneNotDone, setSuptumDoneNotDone] = useState(null);
  const [sputumSmearResult, setSputumSmearResult] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { patient } = useSelector((state) => state);

  useEffect(() => {
    const { smear_done_or_not_done, smear_examination_date, smear_result } =
      patient.patient;

    setValue(
      "smear_done_or_not_done",
      smear_done_or_not_done
        ? { value: smear_done_or_not_done, label: smear_done_or_not_done }
        : null
    );
    setSuptumDoneNotDone(
      smear_done_or_not_done
        ? { value: smear_done_or_not_done, label: smear_done_or_not_done }
        : null
    );
    setValue("smear_examination_date", smear_examination_date);
    setValue(
      "smear_result",
      smear_result ? { value: smear_result, label: smear_result } : null
    );
    setSputumSmearResult(
      smear_result ? { value: smear_result, label: smear_result } : null
    );
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      updatePatient(
        patient.patient.id,
        {
          smear_done_or_not_done: data.smear_done_or_not_done?.value,
          smear_examination_date: suptumDoneNotDone?.value === 'Done' ? data.smear_examination_date : null,
          smear_result: suptumDoneNotDone?.value === 'Done' ? data.smear_result?.value : null,
        },
        "smear"
      )
    );
    setLoading(false);
  };

  return (
    <Container className="mt-3" fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardBody>
            <CardTitle>
              <h3>Smear MicroScopy</h3>
            </CardTitle>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="smear_done_or_not_done"
                  label="Smear Done or Not Done"
                  control={control}
                  rules={{
                    required: "Smear Done or Not Done is required!",
                  }}
                  options={dones}
                  value={suptumDoneNotDone}
                  setData={setSuptumDoneNotDone}
                  placeholder="Smear Done or Not Done"
                  isRequired={true}
                />
              </Col>
              {suptumDoneNotDone?.value === 'Done' &&
                <>
                  <Col md={4}>
                    <CustomDateInput
                      id="smear_examination_date"
                      label="Sputum Examination Date"
                      register={{
                        ...register("smear_examination_date", {
                          required:
                            suptumDoneNotDone?.value === "Done"
                              ? "Sputum Examination Date is required!"
                              : false,
                        }),
                      }}
                      placeholder="Select Sputum Examination Date"
                      errors={errors}
                      max={currentDate()}
                      disabled={
                        suptumDoneNotDone === null ||
                        suptumDoneNotDone?.value !== "Done"
                      }
                      isRequired={suptumDoneNotDone?.value === "Done"}
                    />
                  </Col>
                  <Col md={4}>
                    <CustomSelect
                      id="smear_result"
                      label="Sputum Smear Result"
                      control={control}
                      rules={{
                        required:
                          suptumDoneNotDone?.value === "Done"
                            ? "Sputum Smear Result is required!"
                            : false,
                      }}
                      options={sputumSmearResults}
                      value={sputumSmearResult}
                      setData={setSputumSmearResult}
                      placeholder="Sputum Smear Result"
                      isRequired={suptumDoneNotDone?.value === "Done"}
                      isDisabled={
                        suptumDoneNotDone === null ||
                        suptumDoneNotDone?.value !== "Done"
                      }
                    />
                  </Col>
                </>}
            </Row>
          </CardBody>
          <CardFooter className="text-right">
          {
              patientMiddleware(role, UPDATE) &&  <Button color="primary" size="sm" type="submit">
              {loading ? "Loading" : "Update"}
            </Button>
             }
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};

export default Smear;
