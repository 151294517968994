import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomTable from "components/Table/Table";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomAlert from "components/Alert/CustomAlert";
import { useEffect } from "react";
import { useCallback } from "react";
import { trueprepResults, prepErrors } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { deletePrep } from "store/actions";
import { createPrep } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { truenatMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { DELETE } from "utils/middleware";
import { getSolution } from "variables/prepErrors";
import TruenatPrepRemarkUpdate from "./TrueprepRemarkUpdate";

const Trueprep = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const [alert, setAlert] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedPrepError, setSelectedPrepError] = useState(null);
  const [updateRemark, setUpdateRemark] = useState(null);

  const dispatch = useDispatch();
  const { prep, stock, status, plus, truenat } = useSelector((state) => state);

  useEffect(() => {
    const { specimen_received_date } = truenat.truenat;

    setValue("testing_date", specimen_received_date);
  }, [setValue, truenat.truenat]);

  const stocks = stock.stocks.map((stock) => {
    return { value: stock.id, label: stock.name };
  });

  const defaultPack = stock.stocks.find((stock) => stock.id === 1);
  const defaultStock = stock.stocks.find((stock) => stock.id === 2);

  const setDefault = useCallback(() => {
    setValue("pack", { value: defaultPack?.id, label: defaultPack?.name });
    setSelectedPack({ value: defaultPack?.id, label: defaultPack?.name });
    setValue("stock", { value: defaultStock?.id, label: defaultStock?.name });
    setSelectedItem({ value: defaultStock?.id, label: defaultStock?.name });
  }, [setValue, defaultPack, defaultStock]);

  useEffect(() => {
    setDefault();
  }, [setDefault]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      createPrep({
        truenat_id: id,
        testing_date: data.testing_date,
        pack_id: data.pack.value,
        stock_id: data.stock.value,
        result: data.result.value,
        error:
          selectedResult?.value === "Isolation failure or error"
            ? data.error.value
            : null,
        remark: data.remark,
      })
    );
    setLoading(false);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deletePrep(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        label="You won't be able to recover this."
      />
    );
  };

  const formReset = useCallback(() => {
    setSelectedResult(null);
    setSelectedPrepError(null);
    reset();
    setDefault();
  }, [reset, setSelectedResult, setSelectedPrepError, setDefault]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  const toggle = () => {
      setUpdateRemark(null)
  }

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Trueprep</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="testing_date"
                    label="Testing Date"
                    register={{
                      ...register("testing_date", {
                        required: "Testing Date is required!",
                      }),
                    }}
                    placeholder="Select Testing Date"
                    errors={errors}
                    isRequired={true}
                    max={currentDate()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="pack"
                    label="Pack"
                    rules={{ required: "Pack is required!" }}
                    control={control}
                    options={stocks}
                    value={selectedPack}
                    setData={setSelectedPack}
                    placeholder="Pack"
                    isDisabled={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="stock"
                    label="Equipment"
                    rules={{ required: "Equipment is required!" }}
                    control={control}
                    options={stocks}
                    value={selectedItem}
                    setData={setSelectedItem}
                    placeholder="Equipment"
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="result"
                    label="Result"
                    rules={{ required: "Result is required!" }}
                    control={control}
                    options={trueprepResults}
                    value={selectedResult}
                    setData={setSelectedResult}
                    placeholder="Result"
                  />
                </Col>
              </Row>
              {selectedPrepError && (
                <>
                  <h3
                    style={{
                      color: "#2F76BA",
                    }}
                  >
                    SOLUTION
                  </h3>
                  <p
                    style={{
                      color: "#f00",
                      fontWeight: "bold",
                    }}
                  >
                    {getSolution(selectedPrepError?.value)}
                  </p>
                </>
              )}
              {selectedResult?.value === "Isolation failure or error" && (
                <Row>
                  <Col md={4}>
                    <CustomSelect
                      id="error"
                      label="Error"
                      rules={{ required: "Error is required!" }}
                      control={control}
                      options={prepErrors}
                      value={selectedPrepError}
                      setData={setSelectedPrepError}
                      placeholder="Error"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <CustomTextArea
                    id="remark"
                    label="Remark"
                    register={{
                      ...register("remark"),
                    }}
                    placeholder="Enter Remark"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
              </Row>
            </CardBody>
            {truenatMiddleware(role, CREATE) && (
              <CardFooter className="text-right">
                {prep?.preps[prep?.preps.length - 1]?.result !==
                  "DNA isolated" && (
                  <Button
                    color="primary"
                    size="sm"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Loading" : "Save"}
                  </Button>
                )}
              </CardFooter>
            )}
          </Card>
        </Form>
        <Card>
          <CardBody>
            <CustomTable
              header={
                <tr>
                  <th className="fixed-cell left" scope="col">
                    ID
                  </th>
                  <th className="fixed-cell left" scope="col">
                    Testing Date
                  </th>
                  <th>Result</th>
                  <th>Error</th>
                  <th>Remark</th>
                  {truenatMiddleware(role, DELETE) &&
                    plus?.pluses.length === 0 && <th scope="col">Action</th>}
                </tr>
              }
              body={prep.preps.map((prep, index) => (
                <tr key={prep.id}>
                  <td>{index + 1}</td>
                  <td>{prep.testing_date}</td>
                  <td>{prep.result}</td>
                  <td>{prep.error}</td>
                  <td>{prep.remark}</td>

                  {truenatMiddleware(role, DELETE) &&
                    plus?.pluses.length === 0 && (
                      <td>
                        {prep?.result === "Machine error" && (
                          <Button
                            size="sm"
                            color="warning"
                            onClick={() => setUpdateRemark({
                              id: prep.id,
                              remark: prep.remark
                            })}
                          >
                            Update Remark
                          </Button>
                        )}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleDelete(prep.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                </tr>
              ))}
            />
          </CardBody>
        </Card>
      </Container>

      {
        Boolean(updateRemark) && <TruenatPrepRemarkUpdate isOpen={Boolean(updateRemark)} toggle={toggle} editData={updateRemark} />
      }
    </>
  );
};

export default Trueprep;
