import Login from 'views/pages/auth/Login'
import Dashboard from 'views/pages/dashboard'
import AccountList from 'views/pages/dashboard/account/AccountList'
import DistrictList from 'views/pages/dashboard/basicData/district/DistrictList'
import RegionList from 'views/pages/dashboard/basicData/region/RegionList'
import TownshipList from 'views/pages/dashboard/basicData/township/TownshipList'
import ChangePassword from 'views/pages/auth/ChangePassword'
import PatientList from 'views/pages/dashboard/patient/PatientList'
import PatientCreate from 'views/pages/dashboard/patient/PatientCreate'
import PatientDetail from 'views/pages/dashboard/patient/PatientDetail'
import TruenatList from 'views/pages/dashboard/patient/truenat/TruenatList'
import TruenatCreate from 'views/pages/dashboard/patient/truenat/TruenatCreate'
import TruenatDetail from 'views/pages/dashboard/patient/truenat/TruenatDetail'
import StockList from 'views/pages/dashboard/stock/StockList'
import StockStatusReport from 'views/pages/dashboard/report/Stock'
import TruenatReport from 'views/pages/dashboard/report/Truenat'
import TrashPatientList from 'views/pages/dashboard/trash/TrashPatientList'
import PICTSPatientList from 'views/pages/dashboard/patient/PICTSPatientList'
import MDRPatientList from 'views/pages/dashboard/patient/MDRPatientList'
import TownshipPatientList from 'views/pages/dashboard/patient/TownshipPatientList'
import OtherPatientList from 'views/pages/dashboard/patient/OtherPatientList'

import CovidPatientList from 'views/pages/dashboard/patient/CovidPatientList'

import ReceivedList from 'views/pages/dashboard/stock/stockHistory/ReceivedList'
import DamageList from 'views/pages/dashboard/stock/stockHistory/DamageList'
import ExpiredList from 'views/pages/dashboard/stock/stockHistory/ExpiredList'
import IssuedList from 'views/pages/dashboard/stock/stockHistory/IssuedList'
import HandoutList from 'views/pages/dashboard/handout/HandoutList'
import FormAndRegisterList from 'views/pages/dashboard/formAndRegister/FormAndRegisterList'
import PatientExport from 'views/pages/dashboard/report/PatientExport/PatientExport'


const routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-archive-2 text-green",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    icon: "ni ni-archive-2 text-green",
    component: ChangePassword,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/account-list",
    name: "Accounts",
    icon: "ni ni-single-02 text-primary",
    component: AccountList,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Basic Data",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "basicDataCollapse",
    views: [
      {
        path: "/region-list",
        name: "State/Regions",
        miniName: "RL",
        component: RegionList,
        layout: "/admin",
      },
      {
        path: "/district-list",
        name: "Districts",
        miniName: "DL",
        component: DistrictList,
        layout: "/admin",
      },
      {
        path: "/township-list",
        name: "Townships",
        miniName: "TL",
        component: TownshipList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Lab Register",
    icon: "ni ni-collection text-primary",
    state: "patientDataCollapse",
    views: [
      {
        path: "/all-patient-list",
        name: "All Patients",
        miniName: "PL",
        component: PatientList,
        layout: "/admin",
      },
      {
        path: "/picts-patient-list",
        name: "PICTS",
        miniName: "PL",
        component: PICTSPatientList,
        layout: "/admin",
      },
      {
        path: "/mdr-patient-list",
        name: "MDR",
        miniName: "PL",
        component: MDRPatientList,
        layout: "/admin",
      },
      {
        path: "/covid-patient-list",
        name: "COVID",
        miniName: "CV",
        component: CovidPatientList,
        layout: "/admin",
      },
      {
        path: "/township-patient-list",
        name: "Township",
        miniName: "PL",
        component: TownshipPatientList,
        layout: "/admin",
      },
      {
        path: "/other-patient-list",
        name: "Other",
        miniName: "PL",
        component: OtherPatientList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/patient-create",
    name: "PatientCreate",
    component: PatientCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/patient-detail/:id",
    name: "PatientDetail",
    icon: "ni ni-archive-2 text-purple",
    component: PatientDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: '/truenat-list/:patientId',
    name: "TruenatList",
    icon: "ni ni-archive-2 text-purple",
    component: TruenatList,
    layout: "/admin",
    invisible: true,
  },
  {
    path: '/truenat-create/:patientId',
    name: 'TruenatCreate',
    component: TruenatCreate,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/truenat-detail/:id",
    name: "TruenatDetail",
    icon: "ni ni-archive-2 text-purple",
    component: TruenatDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "Stocks",
    icon: "ni ni-archive-2 text-primary",
    state: "stockDataCollapse",
    views: [
      {
        path: "/stock-record",
        name: "Stock Record",
        miniName: "SR",
        component: StockList,
        layout: "/admin",
      },
      {
        path: "/received-list",
        name: "Received",
        miniName: "RL",
        component: ReceivedList,
        layout: "/admin",
      },
      {
        path: "/damage-list",
        name: "Damage",
        miniName: "DL",
        component: DamageList,
        layout: "/admin",
      },
      {
        path: "/expired-list",
        name: "Expired",
        miniName: "EL",
        component: ExpiredList,
        layout: "/admin",
      },
      {
        path: "/issued-list",
        name: "Issued",
        miniName: "IL",
        component: IssuedList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Report",
    icon: "ni ni-chart-bar-32 text-primary",
    state: "reportCollapse",
    views: [
      {
        path: "/stock-status-report",
        name: "Stock Report",
        miniName: "ST",
        component: StockStatusReport,
        layout: "/admin",
      },
      {
        path: "/truenat-data-report",
        name: "MEAL Report",
        miniName: "TR",
        component: TruenatReport,
        layout: "/admin",
      },
      {
        path: "/patient-export",
        name: "Patient Report",
        miniName: "PR",
        component: PatientExport,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: 'Trash',
    icon: 'ni ni-app text-primary',
    state: 'trashCollapse',
    views: [
      {
        path: '/patient-trash-list',
        name: 'All Patients',
        miniName: 'AP',
        component: TrashPatientList,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/file-upload',
    name: 'File Uploads',
    icon: 'ni ni-archive-2 text-primary',
    component: HandoutList,
    layout: '/admin',
  },
  {
    path: '/form-and-register',
    name: 'Form And Register',
    icon: 'ni ni-single-copy-04 text-primary',
    component: FormAndRegisterList,
    layout: '/admin',
  },
]

export default routes
