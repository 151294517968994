import { call } from "../../services/api";
import {
  CREATE_PLUSES,
  DELETE_PLUSES,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_PLUSES,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getPluses = (truenatId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `pluses/${truenatId}`);
      const result = response.data;

      dispatch({
        type: SHOW_PLUSES,
        payload: result,
      });
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};

export const createPlus = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "pluses", data);
      const result = response.data;

      dispatch({
        type: CREATE_PLUSES,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(
        "Truelab(MTB-Plus) has been created successfully!"
      );
    } catch (_) {}
  };
};

export const deletePlus = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `pluses/${id}`);

      dispatch({ type: DELETE_PLUSES, payload: id });
      NotificationManager.success(
        "Truelab(MTB-Plus) has been deleted successfully!"
      );
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};
