import { STOCK_REPORT, TRUENAT_REPORT,PATIENT_EXPORT } from "../type";

const initialState = {
  stockReport: {},
  truenatReport: {},
  patientExport: [],
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_REPORT:
      return {
        ...state,
        stockReport: action.payload,
      };
    case TRUENAT_REPORT:
      return {
        ...state,
        truenatReport: action.payload,
      };
      case PATIENT_EXPORT:
      return {
        ...state,
        patientExport: action.payload,
      }
    default:
      return state;
  }
};

export default report;
