import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Container,
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { currentDate } from "utils/currentDate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { useDispatch, useSelector } from "react-redux";
import { getPatientExport } from "store/actions/report";
import PatientExportHead from "./PatientExportHead";
import { paginationLimit } from "variables/limits";
import PatientExportCustomPagination from "./PatientExportCustomPagination";

const PatientExport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const router = useHistory();

  const [patientExportCurrentPage, setPatientExportCurrentPage] = useState(1);
  const { patientExport } = useSelector((state) => state.report);

  const { loading } = useSelector((state) => state.status);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getPatientExport(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    if (search) {
      query.search = search;
    } else {
      delete query["search"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };
  const patientExportTotal = patientExport?.length;
  const startIndex = (patientExportCurrentPage - 1) * paginationLimit;
  const endIndex = startIndex + paginationLimit;
  const patientExportUIData = patientExport?.slice(startIndex, endIndex);

  const handleCheck = (value) => {
    if (value === true) {
      return <span>&#x2705;</span>;
    }
    return "";
  };
  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Patient Export UI</h3>
            </Col>
            <Col className="text-right" xs="6">
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="btn btn-sm bg-primary text-white border-0"
                table="table-to-xls"
                filename="Patient export"
                sheet="Patient export"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={4}>
              <input
                className="form-control mt-4"
                type="text"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                value={endDate}
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>
            <Col md={2}>
              <Button
                className="mt-5"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table
          className={classNames("app-table align-items-center table-fixed")}
          responsive
        >
          <PatientExportHead />
          <tbody className="list">
            {patientExportUIData?.map((row, index) => (
              <tr key={index}>
                <td>{row?.no}</td>
                <td>{row["1_specimen_received_date"]}</td>
                <td>{row?.OPD_no}</td>
                <td className="fixed-cell left">{row?.name}</td>
                <td>{row?.age}</td>
                <td>{row?.sex}</td>

                <td>{row?.lab_no}</td>

                <td>{handleCheck(row["1_pretreatment_pack"])}</td>

                <td>{handleCheck(row["1_1_DNA_Isolated"])}</td>
                <td>{handleCheck(row["1_2_DNA_Isolated"])}</td>
                <td>{row["1_1_Isolation_Failure"]}</td>
                <td>{row["1_2_Isolation_Failure"]}</td>
                <td>{handleCheck(row["1_1_plus_invalid"])}</td>
                <td>{handleCheck(row["1_2_plus_invalid"])}</td>
                <td>{row["1_1_plus_error"]}</td>
                <td>{row["1_2_plus_error"]}</td>

                <td>{handleCheck(row["1_Negative"])}</td>
                <td>{handleCheck(row["1_VL"])}</td>
                <td>{handleCheck(row["1_L"])}</td>
                <td>{handleCheck(row["1_M"])}</td>
                <td>{handleCheck(row["1_H"])}</td>

                <td>{handleCheck(row["1_1_rif_invalid"])}</td>
                <td>{handleCheck(row["1_2_rif_invalid"])}</td>
                <td>{row["1_1_rif_error"]}</td>
                <td>{row["1_2_rif_error"]}</td>
                <td>{handleCheck(row["1_1_RR_indeterminate"])}</td>
                <td>{handleCheck(row["1_2_RR_indeterminate"])}</td>
                <td>{handleCheck(row["1_RR_negative"])}</td>
                <td>{handleCheck(row["1_RR_plus"])}</td>
                <td>{row["1_remark"]}</td>
                <td>{row["1_machine_error"] || ""}</td>
                <td>{row["1_machine_error_remark"]}</td>

                <td>{row["2_specimen_received_date"]}</td>
                <td>{handleCheck(row["2_pretreatment_pack"])}</td>

                <td>{handleCheck(row["2_1_DNA_Isolated"])}</td>
                <td>{handleCheck(row["2_2_DNA_Isolated"])}</td>
                <td>{row["2_1_Isolation_Failure"]}</td>
                <td>{row["2_2_Isolation_Failure"]}</td>
                <td>{handleCheck(row["2_1_plus_invalid"])}</td>
                <td>{handleCheck(row["2_2_plus_invalid"])}</td>
                <td>{row["2_1_plus_error"]}</td>

                <td>{row["2_2_plus_error"]}</td>

                <td>{handleCheck(row["2_Negative"])}</td>
                <td>{handleCheck(row["2_VL"])}</td>
                <td>{handleCheck(row["2_L"])}</td>
                <td>{handleCheck(row["2_M"])}</td>
                <td>{handleCheck(row["2_H"])}</td>

                <td>{handleCheck(row["2_1_rif_invalid"])}</td>
                <td>{handleCheck(row["2_2_rif_invalid"])}</td>
                <td>{row["2_1_rif_error"]}</td>
                <td>{row["2_2_rif_error"]}</td>
                <td>{handleCheck(row["2_1_RR_indeterminate"])}</td>
                <td>{handleCheck(row["2_2_RR_indeterminate"])}</td>
                <td>{handleCheck(row["2_RR_negative"])}</td>
                <td>{handleCheck(row["2_RR_plus"])}</td>
                <td>{row["2_remark"]}</td>
                <td>{row["2_machine_error"] || ""}</td>
                <td>{row["2_machine_error_remark"]}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {patientExportTotal > paginationLimit && (
          <PatientExportCustomPagination
            page={patientExportCurrentPage}
            pageCount={patientExportTotal / paginationLimit}
            setPatientExportCurrentPage={setPatientExportCurrentPage}
          />
        )}

        <div
          style={{
            display: "none",
          }}
        >
          <Table
            className={classNames("app-table align-items-center table-fixed")}
            responsive
            id="table-to-xls"
          >
            <PatientExportHead />
            <tbody className="list">
              {patientExport?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.no}</td>
                  <td>{row["1_specimen_received_date"]}</td>
                  <td>{row?.OPD_no}</td>
                  <td className="fixed-cell left">{row?.name}</td>
                  <td>{row?.age}</td>
                  <td>{row?.sex}</td>

                  <td>{row?.lab_no}</td>

                  <td>{handleCheck(row["1_pretreatment_pack"])}</td>

                  <td>{handleCheck(row["1_1_DNA_Isolated"])}</td>
                  <td>{handleCheck(row["1_2_DNA_Isolated"])}</td>
                  <td>{row["1_1_Isolation_Failure"]}</td>
                  <td>{row["1_2_Isolation_Failure"]}</td>
                  <td>{handleCheck(row["1_1_plus_invalid"])}</td>
                  <td>{handleCheck(row["1_2_plus_invalid"])}</td>
                  <td>{row["1_1_plus_error"]}</td>
                  <td>{row["1_2_plus_error"]}</td>

                  <td>{handleCheck(row["1_Negative"])}</td>
                  <td>{handleCheck(row["1_VL"])}</td>
                  <td>{handleCheck(row["1_L"])}</td>
                  <td>{handleCheck(row["1_M"])}</td>
                  <td>{handleCheck(row["1_H"])}</td>

                  <td>{handleCheck(row["1_1_rif_invalid"])}</td>
                  <td>{handleCheck(row["1_2_rif_invalid"])}</td>
                  <td>{row["1_1_rif_error"]}</td>
                  <td>{row["1_2_rif_error"]}</td>
                  <td>{handleCheck(row["1_1_RR_indeterminate"])}</td>
                  <td>{handleCheck(row["1_2_RR_indeterminate"])}</td>
                  <td>{handleCheck(row["1_RR_negative"])}</td>
                  <td>{handleCheck(row["1_RR_plus"])}</td>
                  <td>{row["1_remark"]}</td>
                  <td>{row["1_machine_error"] || ""}</td>
                  <td>{row["1_machine_error_remark"]}</td>

                  <td>{row["2_specimen_received_date"]}</td>
                  <td>{handleCheck(row["2_pretreatment_pack"])}</td>

                  <td>{handleCheck(row["2_1_DNA_Isolated"])}</td>
                  <td>{handleCheck(row["2_2_DNA_Isolated"])}</td>
                  <td>{row["2_1_Isolation_Failure"]}</td>
                  <td>{row["2_2_Isolation_Failure"]}</td>
                  <td>{handleCheck(row["2_1_plus_invalid"])}</td>
                  <td>{handleCheck(row["2_2_plus_invalid"])}</td>
                  <td>{row["2_1_plus_error"]}</td>

                  <td>{row["2_2_plus_error"]}</td>

                  <td>{handleCheck(row["2_Negative"])}</td>
                  <td>{handleCheck(row["2_VL"])}</td>
                  <td>{handleCheck(row["2_L"])}</td>
                  <td>{handleCheck(row["2_M"])}</td>
                  <td>{handleCheck(row["2_H"])}</td>

                  <td>{handleCheck(row["2_1_rif_invalid"])}</td>
                  <td>{handleCheck(row["2_2_rif_invalid"])}</td>
                  <td>{row["2_1_rif_error"]}</td>
                  <td>{row["2_2_rif_error"]}</td>
                  <td>{handleCheck(row["2_1_RR_indeterminate"])}</td>
                  <td>{handleCheck(row["2_2_RR_indeterminate"])}</td>
                  <td>{handleCheck(row["2_RR_negative"])}</td>
                  <td>{handleCheck(row["2_RR_plus"])}</td>
                  <td>{row["2_remark"]}</td>
                  <td>{row["2_machine_error"] || ""}</td>
                  <td>{row["2_machine_error_remark"]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </Container>
  );
};

export default PatientExport;
