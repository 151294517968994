import { CREATE_STOCK, DELETE_STOCK, SET_STOCK, SHOW_STOCKS, UPDATE_STOCK } from '../type'
  
  const initialState = {
    stocks: [],
    stock: {},
  }
  
  const stock = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_STOCKS:
        return {
          ...state,
          stocks: action.payload,
        }
      case CREATE_STOCK:
        return {
          ...state,
          stocks: [action.payload, ...state.stocks],
        }
      case DELETE_STOCK:
        return {
          ...state,
          stocks: state.stocks.filter((stock) => stock.id !== action.payload),
        }
      case SET_STOCK:
        return {
          ...state,
          stock: action.payload,
        }
      case UPDATE_STOCK:
        if (JSON.stringify(state.stock) !== JSON.stringify(action.payload)) {
          let clone = [...state.stocks]
          let index = clone.findIndex((stock) => stock.id === action.payload.id)
          clone[index] = action.payload
          return {
            ...state,
            stocks: clone,
            stock: action.payload,
          }
        } else {
          return state
        }
      default:
        return state
    }
  }
  
  export default stock
  