export const roles = [
  {
    value: "Senior Technical Adviser (TB)",
    label: "Senior Technical Adviser (TB)",
  },
  {
    value: 'Programme Director',
    label: 'Programme Director',
  },
  {
    value: "Deputy Programme Director_TB",
    label: "Deputy Programme Director_TB",
  },
  {
    value: "Deputy Programme Director_MDR",
    label: "Deputy Programme Director_MDR",
  },
  { value: "Programme Manager_TB", label: "Programme Manager_TB" },
  { value: "Programme Manager_AIS", label: "Programme Manager_AIS" },
  { value: "Programme Manager_MDR", label: "Programme Manager_MDR" },
  {
    value: "MEAL and Digital Tool Manager",
    label: "MEAL and Digital Tool Manager",
  },
  {
    value: "MEAL And Digital Tool Officer",
    label: "MEAL And Digital Tool Officer",
  },
  { value: "MEAL Associate", label: "MEAL Associate" },
  // { value: "Project Officer", label: "Project Officer" },
  // { value: "Field Officer (Focal)", label: "Field Officer (Focal)" },
  { value: "Field Officer_TB", label: "Field Officer_TB" },
  { value: "Field Officer_MDR", label: "Field Officer_MDR" },
  {
    value: "Medical Officer",
    label: "Medical Officer",
  },
  {
    value: "Nurse for AIS Clinic",
    label: "Nurse for AIS Clinic",
  },
  {
    value: "Lab Associate",
    label: "Lab Associate",
  },
  // { value: 'Nurse Counsellor', label: 'Nurse Counsellor' },
  // { value: 'Developer', label: 'Developer' },
];

export const TBCodeRegistration = [
  { value: "Township", label: "Township" },
  { value: "Union", label: "Union" },
];

export const sex = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const typeOfTBDependingOnBACStatus = [
  { value: "BC", label: "BC" },
  { value: "CD", label: "CD" },
];

export const treatmentRegimen = [
  { value: "IR", label: "IR" },
  { value: "RR", label: "RR" },
  { value: "CR", label: "CR" },
  { value: "MR", label: "MR" },
];

export const yesOrNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export const isOrIsNot = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
export const isTransferIn = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Transfer in", label: "Transfer in" },
];

export const timeAfterWakingToFirstCigarette = [
  { value: "<= 30 min", label: "<= 30 min" },
  { value: ">30 min", label: ">30 min" },
];

export const followUpOption = [
  { value: "Phone Call", label: "Phone Call" },
  { value: "In Person", label: "In Person" },
];

export const statusOfSmokingForThisFollowUpToSkipForTIPatient = [
  { value: "S", label: "S" },
  { value: "R", label: "R" },
  { value: "Q", label: "Q" },
  { value: "D", label: "D" },
  { value: "L", label: "L" },
];

export const years = [
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

export const regimenTypes = [
  { value: "IR - Initial Regimen", label: "IR - Initial Regimen" },
  { value: "RR - Retreatment Regimen", label: "RR - Retreatment Regimen" },
  { value: "CR - Childhood Regimen", label: "CR - Childhood Regimen" },
  { value: "MR - Modified Regimen", label: "MR - Modified Regimen" },
];

export const educations = [
  { value: "Primary", label: "Primary" },
  { value: "Secondary", label: "Secondary" },
  { value: "Higher", label: "Higher" },
];

export const occuptions = [
  { value: "AMW", label: "AMW" },
  { value: "CHW", label: "CHW" },
  { value: "Other", label: "Other" },
];

export const phoneSupporteds = [
  { value: "Not Supported", label: "Not Supported" },
  { value: "AIS", label: "AIS" },
  { value: "GF", label: "GF" },
];

export const reportMonths = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
  { value: "1 Quarter", label: "1st Quarter" },
  { value: "2 Quarter", label: "2nd Quarter" },
  { value: "3 Quarter", label: "3rd Quarter" },
  { value: "4 Quarter", label: "4th Quarter" },
  { value: "Annual", label: "Annual" },
];

export const outcomeMonths = [
  { value: "1 Quarter", label: "1st Quarter" },
  { value: "2 Quarter", label: "2nd Quarter" },
  { value: "3 Quarter", label: "3rd Quarter" },
  { value: "4 Quarter", label: "4th Quarter" },
  { value: "Annual", label: "Annual" },
];

export const referredTreatmentUnits = [
  { value: "Union", label: "Union" },
  { value: "Township", label: "Township" },
  { value: "Walk in patient", label: "Walk in patient" },
  { value: "Other", label: "Other" },
];

export const referredUnions = [
  // { value: "PICTS", label: "PICTS" },
  { value: "CBTBC", label: "CBTBC" },
  { value: "MDR", label: "MDR" },
  { value: "COVID", label: "COVID" },
];
export const controlKitStockHistoryResults = [
  { value: "Invalid", label: "Invalid" },
  { value: "Positive", label: "Positive" },
];

export const referredOthers = [
  { value: "Township", label: "Township" },
  { value: "Other", label: "Other" },
];

export const ngePosUnkStatus = [
  { value: "Negative", label: "Negative" },
  { value: "Positive", label: "Positive" },
  { value: "Unknown", label: "Unknown" },
];

export const yesNoUnkStatus = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Unknown", label: "Unknown" },
];

export const examinationTypes = [
  { value: "Diagnosis", label: "Diagnosis" },
  { value: "F/U(Month)", label: "F/U(Month)" },
];

export const dones = [
  { value: "Done", label: "Done" },
  { value: "Not Done", label: "Not Done" },
];

export const sputumSmearResults = [
  { value: "Negative", label: "Negative" },
  { value: "Positive", label: "Positive" },
  { value: "Pending", label: "Pending" },
];

export const geneXpertResults = [
  { value: "N-MTB Not Detected", label: "N-MTB Not Detected" },
  { value: "T-MTB detected", label: "T-MTB detected" },
  { value: "RR-Rif resistance", label: "RR-Rif resistance" },
  {
    value: "TI-MTB detected, RR indeterminate",
    label: "TI-MTB detected, RR indeterminate",
  },
  { value: "I-invalid result", label: "I-invalid result" },
  { value: "Pending", label: "Pending" },
];

export const trueprepResults = [
  { value: "DNA isolated", label: "DNA isolated" },
  { value: "Isolation failure or error", label: "Isolation failure or error" },
  { value: "Machine error", label: "Machine error" },
];

export const truelabMTBPlusResults = [
  { value: "MTB-Not Detected", label: "MTB-Not Detected" },
  { value: "Invalid", label: "Invalid" },
  { value: "Error", label: "Error" },
  { value: "MTB-Detected (VL)", label: "MTB-Detected (VL)" },
  { value: "MTB-Detected (L)", label: "MTB-Detected (L)" },
  { value: "MTB-Detected (M)", label: "MTB-Detected (M)" },
  { value: "MTB-Detected (H)", label: "MTB-Detected (H)" },
];

export const truelabMTBChipResults = [
  { value: "MTB-Detected", label: "MTB-Detected" },
  { value: "MTB-Not Detected", label: "MTB-Not Detected" },
  { value: "Invalid", label: "Invalid" },
  { value: "Machine error", label: "Machine error" },
];

export const truelabMTBRIFResults = [
  { value: "MTB-Detected, RR (-)", label: "MTB-Detected, RR (-)" },
  { value: "MTB-Detected, RR (+)", label: "MTB-Detected, RR (+)" },
  {
    value: "MTB-Detected, RR-Indeterminate",
    label: "MTB-Detected, RR-Indeterminate",
  },
  { value: "Invalid", label: "Invalid" },
  { value: "Error", label: "Error" },
];

export const items = [
  {
    value: "Truenat MTB Plus micro PCR chips",
    label: "Truenat MTB Plus micro PCR chips",
  },
  {
    value: "Trueprep AUTO Universal Cartridge Based Sample Prep",
    label: "Trueprep AUTO Universal Cartridge Based Sample Prep",
  },
  {
    value: "Trueprep Auto MTB sample pretreatment pack",
    label: "Trueprep Auto MTB sample pretreatment pack",
  },
  {
    value: "Truenat MTB RIF Dx micro PCR chips",
    label: "Truenat MTB RIF Dx micro PCR chips",
  },
];

export const types = [
  { value: "Received", label: "Received" },
  { value: "Damage", label: "Damage" },
  { value: "Expired", label: "Expired" },
];

export const prepErrors = [
  { value: "E1", label: "E1" },
  { value: "E2", label: "E2" },
  { value: "E3", label: "E3" },
  { value: "E6", label: "E6" },
  { value: "E9", label: "E9" },
  { value: "E10", label: "E10" },
  { value: "E11", label: "E11" },
  { value: "E12", label: "E12" },
];

export const plusErrors = [
  { value: "ERROR 1", label: "ERROR 1" },
  { value: "ERROR 2", label: "ERROR 2" },
  { value: "ERROR 3", label: "ERROR 3" },
  { value: "ERROR 4", label: "ERROR 4" },
  { value: "ERROR 5", label: "ERROR 5" },
]

export const rifErrors = [
  { value: "ERROR 1", label: "ERROR 1" },
  { value: "ERROR 2", label: "ERROR 2" },
  { value: "ERROR 3", label: "ERROR 3" },
  { value: "ERROR 4", label: "ERROR 4" },
  { value: "ERROR 5", label: "ERROR 5" },
  { value: "ERROR 8", label: "ERROR 8" },
]
