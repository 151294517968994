import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  DEVELOPER,
  HIS_AND_DIGITAL_LISTERACY_MANAGER,
  MEAL_AND_DIGITAL_TOOL_OFFICER,
  MEAL_ASSOCIATE,
} from "utils/middleware";


const forbiddenRoutes = [
  "/admin/dashboard",
  "/admin/account-list",
  "/admin/region-list",
  "/admin/district-list",
  "/admin/township-list",
];

const AuthorizedRoute = ({ component: Component, path, ...rest }) => {
  const { user } = useSelector((state) => state.auth);
  const { role } = user;

  const isAuthorized = () => {
    if (
      (role !== DEVELOPER &&
        role !== HIS_AND_DIGITAL_LISTERACY_MANAGER &&
        role !== MEAL_AND_DIGITAL_TOOL_OFFICER &&
        role !== MEAL_ASSOCIATE) &&
      forbiddenRoutes.includes(path)
    ) {
      return false;
    }
    return true;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/all-patient-list" />
        )
      }
    />
  );
};

const mapStateToProps = (store) => ({
  auth: store.auth,
});

export default connect(mapStateToProps)(AuthorizedRoute);
