import { call } from "../../services/api";
import {
  CREATE_RIFS,
  DELETE_RIFS,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_RIFS,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getRifs = (truenatId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `rifs/${truenatId}`);
      const result = response.data;

      dispatch({
        type: SHOW_RIFS,
        payload: result,
      });
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};

export const createRif = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "rifs", data);
      const result = response.data;

      dispatch({
        type: CREATE_RIFS,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(
        "Truelab(MTB-RIF) has been created successfully!"
      );
    } catch (_) {}
  };
};

export const deleteRif = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `rifs/${id}`);

      dispatch({ type: DELETE_RIFS, payload: id });
      NotificationManager.success(
        "Truelab(MTB-RIF) has been deleted successfully!"
      );
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};
