import { call } from '../../services/api'
import {
    CREATE_TRUENAT,
  DELETE_TRUENAT,
  SET_LOADING,
  SET_SUCCESS,
  SET_TRUENAT,
  SHOW_TRUENATS,
  UPDATE_TRUENAT,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getTruenats = (patientId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', `truenats/${patientId}`)
      const result = response.data

      dispatch({
        type: SHOW_TRUENATS,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const getTruenat = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `truenat/${id}`)
      const result = response.data

      dispatch({
        type: SET_TRUENAT,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const createTruenat = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'truenats', data)
      const result = response.data

      dispatch({
        type: CREATE_TRUENAT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Specimen has been created successfully!')
    } catch (_) { }
  }
}

export const updateTruenat = (id, data, path) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    })
    try {
      const response = await call('post', `${path}/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_TRUENAT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Specimen has been updated successfully!')
    } catch (_) { }
  }
}

export const deleteTruenat = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `truenats/${id}`)

      dispatch({ type: DELETE_TRUENAT, payload: id })
      NotificationManager.success('Specimen has been deleted successfully!')
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}
