import React, { useState, useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BackBtn from "utils/backBtn";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPatients } from "store/actions";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import CustomAlert from "components/Alert/CustomAlert";
import { temDeletePatient } from "store/actions";
import { call } from "services/api";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { patientMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { DELETE } from "utils/middleware";

const MDRPatientList = () => {
  const router = useHistory();

  const [alert, setAlert] = useState(null);

  const [exportLoading, setExportLoading] = useState(false);

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { status, patient } = useSelector((state) => state);
  const { patients, male, female, total } = patient;

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.mdr = true;
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getPatients(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    query.mdr = true;
    if (search) {
      query.search = search;
    } else {
      delete query["search"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(temDeletePatient(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.mdr = true;
      const response = await call(
        "get",
        `patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        unique_key: data.id,
        no: index + 1,
        OPD_no: data.OPD_no,
        lab_no: data.lab_no,
        registration_date: data.registration_date,
        name: data.name,
        sex: data.sex,
        age: data.age,
        "Address,Phone": data.address,
        // phone: data.phone,
        presumptive_code: data.presumptive_code,
        referred_treatment_unit: data.referred_treatment_unit,
        referred_union: data.referred_union,
        referred_other: data.referred_other,
        referred_other_other: data.referred_other_other,
        HIV_status: data.HIV_status,
        DM_status: data.DM_status,
        previously_treated_for_TB: data.previously_treated_for_TB,
        examination_type: data.examination_type,
        FU_month: data.FU_month,
        smear_done_or_not_done: data.smear_done_or_not_done,
        smear_examination_date: data.smear_examination_date,
        smear_result: data.smear_result,
        gene_done_or_not_done: data.gene_done_or_not_done,
        gene_examination_date: data.gene_examination_date,
        gene_result: data.gene_result,
        remark: data.remark,
      }));

      let fileName = "MDR-Patients";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">MDR Patient List</h3>
              </Col>
              <Col className="text-right" xs="6">
                {patientMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => router.push(`/admin/patient-create`)}
                  >
                    New
                  </Button>
                )}
                <Button
                  size="sm"
                  color="info"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? "Loading" : "Export"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <input
                  className="form-control mt-3"
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-3"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Male = {male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Female = {female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-3"
                >
                  Total = {total}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  OPD No
                </th>
                <th className="fixed-cell left" scope="col">
                  Lab No
                </th>
                <th className="fixed-cell left" scope="col">
                  Presumptive Code
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th scope="col">Sex</th>
                <th scope="col">Age</th>
                <th scope="col">Phone</th>
                <th scope="col">Address</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={patients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{index + 1}</td>
                <td>{patient.OPD_no}</td>
                <td
                  style={
                    !patient.smear_done_or_not_done ||
                      !patient.gene_done_or_not_done ||
                      patient.truenats.length === 0
                      ? {
                        backgroundColor: "#f00",
                        color: "#fff",
                      }
                      : {}
                  }
                >
                  {patient.lab_no}
                </td>
                <td>{patient.presumptive_code}</td>
                <td>{patient.name}</td>
                <td>{patient.sex}</td>
                <td>{patient.age}</td>
                <td>{patient.phone}</td>
                <td>{patient.address}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/patient-detail/${patient.id}`)
                    }
                  >
                    Detail
                  </Button>
                  {patientMiddleware(role, DELETE) && (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(patient.id)}
                    >
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default MDRPatientList;
