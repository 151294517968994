import React from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'

const CustomAlert = ({ onConfirm, onCancel, toTrash = false, label = `You won't be able to recover this!` }) => {
  return (
    <ReactBSAlert
      warning
      title="Are you sure?"
      onConfirm={onConfirm}
      onCancel={onCancel}
      showCancel
      confirmBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnBsStyle="secondary"
      cancelBtnText="Cancel"
    >
      {toTrash ? '' : label}
    </ReactBSAlert>
  )
}

export default CustomAlert
