import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { updateStock } from 'store/actions'

const StockUpdate = ({
    isOpen,
    toggle,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm()

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const { status, stock } = useSelector((state) => state);

    useEffect(() => {
        const { name } = stock.stock

        setValue('name', name)
    }, [setValue, stock.stock])

    const onSubmit = async (data) => {
        setLoading(true)
        await dispatch(updateStock(stock.stock.id, data))
        setLoading(false)
    }

    useEffect(() => {
        if (status.success) {
            toggle(false)
        }
    }, [status.success, toggle])

    return (
        <CustomModal
            isOpen={isOpen}
            title="Update Stock"
            onClick={() => toggle(false)}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                    <CustomInput
                        id="name"
                        label="Name"
                        register={{
                            ...register('name', {
                                required: 'Name is required!',
                            }),
                        }}
                        placeholder="Enter name"
                        errors={errors}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="primary" size="sm" type="submit" disabled={loading}>
                        {loading ? 'Loading' : 'Update'}
                    </Button>
                </div>
            </Form>
        </CustomModal>
    )
}

export default StockUpdate
