import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import CustomTable from "components/Table/Table";
import HandoutCreate from "./HandoutCreate";
import { connect } from "react-redux";
import { getHandouts } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { handoutMiddleware, CREATE } from "utils/middleware";
import BackBtn from "utils/backBtn";

const HandoutList = ({ status, auth, handout, getHandouts }) => {
  const { role } = auth.user;

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  useEffect(() => {
    getHandouts();
  }, [getHandouts]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">File Uploads</h3>
              </Col>
              {handoutMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>

                <th scope="col">File Link</th>
              </tr>
            }
            body={handout.handouts.map((handout, index) => (

              <tr key={handout.id}>
                <td>{index + 1}.</td>
                <td>{handout?.name}</td>
                <td>
                  <a href={handout?.file} >
                    Click Link To Download File
                  </a>
                </td>
              </tr>
            ))}
          />
        </Card>
      </Container>

      <HandoutCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  handout: store.handout,
});

export default connect(mapStateToProps, {
  getHandouts,
})(HandoutList);
