import { combineReducers } from 'redux'
import status from './status'
import auth from './auth'
import account from './account'
import region from './region'
import district from './district'
import township from './township'
import stock from './stock'
import stockHistory from './stockHistory'
import patient from './patient'
import truenat from './truenat'
import plus from './plus'
import rif from './rif'
import prep from './prep'
import report from './report'
import handout from './handout'
import formAndRegister from './formAndRegister'

const reducers = combineReducers({
  status,
  auth,
  account,
  region,
  district,
  township,
  stock,
  stockHistory,
  patient,
  truenat,
  plus,
  rif,
  prep,
  report,
  handout,
  formAndRegister
})

export default reducers
