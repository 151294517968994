import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import { sex, years } from "variables/options";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { referredTreatmentUnits } from "variables/options";
import { referredUnions } from "variables/options";
import { yesNoUnkStatus } from "variables/options";
import { ngePosUnkStatus } from "variables/options";
import { examinationTypes } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { getTownships } from "store/actions";
import { useCallback } from "react";
import { createPatient } from "store/actions";
import { useHistory } from "react-router";
import CustomDateInput from "components/Inputs/CustomDateInput";

const PatientCreate = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const router = useHistory();

  const [loading, setLoading] = useState(false);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedReferredTreatmentUnit, setSelectedReferredTreatmentUnit] =
    useState(null);
  const [selectedReferredUnion, setSelectedReferredUnion] = useState(null);
  const [selectedHIVStatus, setSelectedHIVStatus] = useState(null);
  const [selectedDMStatus, setSelectedDMStatus] = useState(null);
  const [selectedPreviouslyTreatedForTB, setSelectedPreviouslyTreatedForTB] =
    useState(null);
  const [selectedExaminationTypes, setSelectedExaminationTypes] =
    useState(null);

  const dispatch = useDispatch();
  const { status, township, patient } = useSelector((state) => state);

  const townships = township.townships.map((township) => {
    return { value: township.id, label: township.name };
  });

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      createPatient({
        township_id: data.township?.value,
        OPD_no: data.OPD_no,
        lab_no: data.lab_no,
        registration_date: data.registration_date,
        name: data.name,
        sex: data.sex?.value,
        age: data.age,
        address: data.address,
        // phone: data.phone,
        code: data.code,
        year: data.year?.value,
        referred_treatment_unit: data.referred_treatment_unit?.value,
        referred_union:
          selectedReferredTreatmentUnit?.value === "Union"
            ? data.referred_union?.value
            : null,

        referred_other_other:
          selectedReferredTreatmentUnit?.value === "Other"
            ? data.referred_other_other
            : null,
        HIV_status: data.HIV_status?.value,
        DM_status: data.DM_status?.value,
        previously_treated_for_TB: data.previously_treated_for_TB?.value,
        examination_type: data.examination_type?.value,
        FU_month:
          selectedExaminationTypes?.value === "F/U(Month)"
            ? data.FU_month
            : null,
      })
    );
    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedSex(null);
    setSelectedTownship(null);
    setSelectedYear(null);
    setSelectedReferredTreatmentUnit(null);
    setSelectedReferredUnion(null);

    setSelectedHIVStatus(null);
    setSelectedDMStatus(null);
    setSelectedPreviouslyTreatedForTB(null);
    setSelectedExaminationTypes(null);
    reset();
  }, [
    reset,
    setSelectedSex,
    setSelectedTownship,
    setSelectedYear,
    setSelectedReferredTreatmentUnit,
    setSelectedReferredUnion,

    setSelectedHIVStatus,
    setSelectedDMStatus,
    setSelectedPreviouslyTreatedForTB,
    setSelectedExaminationTypes,
  ]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Create Patient</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="OPD_no"
                    label="OPD No"
                    register={{
                      ...register("OPD_no", {
                        required: "OPD_no is required!",
                      }),
                    }}
                    placeholder="Enter OPD No"
                    errors={errors}
                    isRequired={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomNumberInput
                    id="lab_no"
                    label="Lab No"
                    register={{
                      ...register("lab_no", {
                        required: "Lab No is required!",
                      }),
                    }}
                    placeholder="Enter Lab No"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="registration_date"
                    label="Registration Date"
                    register={{
                      ...register("registration_date", {
                        required: true,
                      }),
                    }}
                    placeholder="Select Registration Date"
                    errors={errors}
                    isRequired={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="name"
                    label="Name"
                    register={{
                      ...register("name", {
                        required: "Name is required!",
                      }),
                    }}
                    placeholder="Enter Name"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="sex"
                    label="Sex"
                    rules={{ required: "Sex is required!" }}
                    control={control}
                    options={sex}
                    value={selectedSex}
                    setData={setSelectedSex}
                    placeholder="Sex"
                  />
                </Col>
                <Col>
                  <CustomNumberInput
                    id="age"
                    label="Age"
                    register={{
                      ...register("age", {
                        required: "Age is required!",
                      }),
                    }}
                    placeholder="Enter age"
                    errors={errors}
                    max={120}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="township"
                    label="Township"
                    rules={{ required: "Township is required!" }}
                    control={control}
                    options={townships}
                    value={selectedTownship}
                    setData={setSelectedTownship}
                    placeholder="Township"
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="address"
                    label="Address, Phone"
                    register={{
                      ...register("address", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Address, Phone"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
                {/* <Col md={4}>
                  <CustomInput
                    id="phone"
                    label="Phone"
                    register={{
                      ...register("phone", {
                        required: "Phone is required!",
                      }),
                    }}
                    placeholder="Enter Phone"
                    errors={errors}
                  />
                </Col> */}
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="referred_treatment_unit"
                    label="Referred by"
                    rules={{ required: "Referred by is required!" }}
                    control={control}
                    options={referredTreatmentUnits}
                    value={selectedReferredTreatmentUnit}
                    setData={(value) => {
                      setSelectedReferredTreatmentUnit(value);
                      setSelectedReferredUnion(null);

                      setSelectedYear(null);
                    }}
                    placeholder="Referred by"
                  />
                </Col>
                {selectedReferredTreatmentUnit?.value === "Union" && (
                  <Col md={4}>
                    <CustomSelect
                      id="referred_union"
                      label="Referred Union"
                      rules={{ required: "Referred Union is required!" }}
                      control={control}
                      options={referredUnions}
                      value={selectedReferredUnion}
                      setData={setSelectedReferredUnion}
                      placeholder="Referred Union"
                    />
                  </Col>
                )}

                {selectedReferredTreatmentUnit?.value === "Other" && (
                  <Col md={4}>
                    <CustomInput
                      id="referred_other_other"
                      label="Other"
                      register={{
                        ...register("referred_other_other", {
                          required: "Other is required!",
                        }),
                      }}
                      placeholder="Enter Other"
                      errors={errors}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                {/* <Col md={4}>
                  <CustomInput
                    id="code"
                    label="Code"
                    register={{
                      ...register("code", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Code"
                    errors={errors}
                    isRequired={false}
                  />
                </Col> */}
                <Col md={4}>
                  <CustomSelect
                    id="year"
                    label="Year"
                    rules={{ required: "Year is required!" }}
                    control={control}
                    options={years}
                    value={selectedYear}
                    setData={setSelectedYear}
                    placeholder="Year"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="HIV_status"
                    label="HIV Status"
                    rules={{ required: "HIV Status is required!" }}
                    control={control}
                    options={ngePosUnkStatus}
                    value={selectedHIVStatus}
                    setData={setSelectedHIVStatus}
                    placeholder="HIV Status"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="DM_status"
                    label="DM Status"
                    rules={{ required: "DM Status is required!" }}
                    control={control}
                    options={yesNoUnkStatus}
                    value={selectedDMStatus}
                    setData={setSelectedDMStatus}
                    placeholder="DM Status"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="previously_treated_for_TB"
                    label="Previously Treated For TB"
                    rules={{
                      required: "Previously Treated For TB is required!",
                    }}
                    control={control}
                    options={yesNoUnkStatus}
                    value={selectedPreviouslyTreatedForTB}
                    setData={setSelectedPreviouslyTreatedForTB}
                    placeholder="Previously Treated For TB"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="examination_type"
                    label="Examination Type"
                    rules={{ required: "Examination Type is required!" }}
                    control={control}
                    options={examinationTypes}
                    value={selectedExaminationTypes}
                    setData={setSelectedExaminationTypes}
                    placeholder="Examination Type"
                  />
                </Col>
                {selectedExaminationTypes?.value === "F/U(Month)" && (
                  <Col md={4}>
                    <CustomNumberInput
                      type="number"
                      min={1}
                      max={99}
                      id="FU_month"
                      label="F/U(Month)"
                      register={{
                        ...register("FU_month", {
                          required: "F/U(Month) is required!",
                        }),
                      }}
                      placeholder="Enter F/U(Month)"
                      errors={errors}
                    />
                  </Col>
                )}
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              <Button color="primary" size="sm" type="submit">
                {loading ? "Loading" : "Save"}
              </Button>
              {!!Object.keys(patient.createdPatient).length && (
                <Button
                  size="sm"
                  color="success"
                  onClick={() =>
                    router.push(
                      `/admin/patient-detail/${patient.createdPatient.id}`
                    )
                  }
                >
                  Detail
                </Button>
              )}
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default PatientCreate;
