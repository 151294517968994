import { call } from "../../services/api";
import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  SET_LOADING,
  SET_PATIENT,
  SET_REPEAT,
  SET_SUCCESS,
  SHOW_PATIENTS,
  UPDATE_PATIENT,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getPatients = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `patients?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: SHOW_PATIENTS,
        payload: result,
      });
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const getPatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("get", `patients/${id}`);
      const result = response.data;

      dispatch({
        type: SET_PATIENT,
        payload: result,
      });
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const createPatient = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "patients", data);
      const result = response.data;

      dispatch({
        type: CREATE_PATIENT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Patient has been created successfully!");
    } catch (_) { }
  };
};

export const updatePatient = (id, data, path) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    });
    try {
      const response = await call("post", `${path}/${id}?_method=PUT`, data);
      const result = response.data;

      dispatch({
        type: UPDATE_PATIENT,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Patient has been updated successfully!");
    } catch (_) { }
  };
};

export const recoverPatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("get", `patients-recover/${id}`);

      dispatch({ type: DELETE_PATIENT, payload: id });
      NotificationManager.success("Patient has been recovered successfully!");
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const temDeletePatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("get", `patients-delete/${id}`);

      dispatch({ type: DELETE_PATIENT, payload: id });
      NotificationManager.success("Patient has been deleted successfully!");
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const deletePatient = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `patients/${id}`);

      dispatch({ type: DELETE_PATIENT, payload: id });
      NotificationManager.success("Patient has been deleted successfully!");
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const getRepeat = (patientId, index) => {
  return async (dispatch) => {
    try {
      const response = await call("get", `is-repeat/${patientId}/${index}`);
      const result = response.data;

      dispatch({
        type: SET_REPEAT,
        payload: result,
      });
    } catch (_) { }
  };
};
