import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_HANDOUT,
  SET_LOADING,
  SET_SUCCESS,
  SET_HANDOUT,
  SHOW_HANDOUTS,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getHandouts = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', 'handouts')
      const result = response.data

      dispatch({
        type: SHOW_HANDOUTS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getHandout = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `handouts/${id}`)
      const result = response.data

      dispatch({
        type: SET_HANDOUT,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createHandout = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'handouts', data)
      const result = response.data

      dispatch({
        type: CREATE_HANDOUT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'File has been uploaded successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

// export const updateHandout = (id, data) => {
//   return async (dispatch) => {
//     try {
//       const response = await call(
//         'post',
//         `handouts/${id}?_method=PUT`,
//         data,
//       )
//       const result = response.data

//       dispatch({
//         type: UPDATE_HANDOUT,
//         payload: result,
//       })
//       dispatch({
//         type: SET_SUCCESS,
//         payload: true,
//       })
//       NotificationManager.success(
//         'Volunteer Handout has been updated successfully!',
//       )
//     } catch (error) {
//       const { status, data } = error.response
//       if (status === 400) {
//         const obj = data.data
//         NotificationManager.error(obj[Object.keys(obj)[0]])
//       } else if (status === 401) {
//         setAccessToken(null)
//         NotificationManager.error(data.data.message)
//       } else {
//         NotificationManager.error(serverErrorMessage)
//       }
//       dispatch({
//         type: SET_SUCCESS,
//         payload: false,
//       })
//     }
//   }
// }
