import { call } from '../../services/api'
import {
  CREATE_REGION,
  DELETE_REGION,
  SET_LOADING,
  SET_REGION,
  SET_SUCCESS,
  SHOW_REGIONS,
  UPDATE_REGION,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getRegions = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', 'regions')
      const result = response.data

      dispatch({
        type: SHOW_REGIONS,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const getRegion = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `regions/${id}`)
      const result = response.data

      dispatch({
        type: SET_REGION,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const createRegion = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'regions', data)
      const result = response.data

      dispatch({
        type: CREATE_REGION,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Region has been created successfully!')
    } catch (_) { }
  }
}

export const updateRegion = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    })
    try {
      const response = await call('post', `regions/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_REGION,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Region has been updated successfully!')
    } catch (_) { }
  }
}

export const deleteRegion = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `regions/${id}`)

      dispatch({ type: DELETE_REGION, payload: id })
      NotificationManager.success('Region has been deleted successfully!')
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}
