import { call } from '../../services/api'
import {
  CREATE_DISTRICT,
  DELETE_DISTRICT,
  SET_LOADING,
  SET_DISTRICT,
  SET_SUCCESS,
  SHOW_DISTRICTS,
  UPDATE_DISTRICT,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getDistricts = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', 'districts')
      const result = response.data

      dispatch({
        type: SHOW_DISTRICTS,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const getDistrict = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `districts/${id}`)
      const result = response.data

      dispatch({
        type: SET_DISTRICT,
        payload: result,
      })
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}

export const createDistrict = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'districts', data)
      const result = response.data

      dispatch({
        type: CREATE_DISTRICT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('District has been created successfully!')
    } catch (_) { }
  }
}

export const updateDistrict = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    })
    try {
      const response = await call('post', `districts/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_DISTRICT,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('District has been updated successfully!')
    } catch (_) { }
  }
}

export const deleteDistrict = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `districts/${id}`)

      dispatch({ type: DELETE_DISTRICT, payload: id })
      NotificationManager.success('District has been deleted successfully!')
    } catch (_) { }
    dispatch({ type: SET_LOADING })
  }
}
