import React, { useState, useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BackBtn from "utils/backBtn";
import StockCreate from "./StockCreate";
import { useDispatch, useSelector } from "react-redux";
import { getStocks } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import StockUpdate from "./StockUpdate";
import { stockMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";

const StockList = () => {

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { status, stock } = useSelector((state) => state);
  const { loading } = status;
  const { stocks } = stock;

  useEffect(() => {
    dispatch(getStocks());
  }, [dispatch]);

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Stock Record</h3>
              </Col>
              <Col className="text-right" xs="6">
                {
                  stockMiddleware(role, CREATE) && <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                }
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Name
                </th>
                <th className="fixed-cell left" scope="col">
                  Opening Balance
                </th>
                {/* {
                  stockMiddleware(role, UPDATE) && <th scope="col">Action</th>
                } */}
              </tr>
            }
            body={stocks.map((stock) => (
              <tr key={stock.id}>
                <td>{stock.id}</td>
                <td>{stock.name}</td>
                <td>{stock.opening_balance}</td>
                {/* {
                  stockMiddleware(role, UPDATE) && <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await dispatch(getStock(stock.id));
                        setIsUpdateOpen(true);
                      }}
                    >
                      Update
                    </Button>
                  </td>
                } */}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <StockCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
      <StockUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
    </>
  );
};

export default StockList;
