export const CREATE = "create";
export const READ = "read";
export const UPDATE = "update";
export const DELETE = "delete";
export const EXPORT = "export";

export const DEVELOPER = "Developer";
// const SENIOR_TECHNICAL_ADVISER_TB = "Senior Technical Adviser (TB)";
// const DEPUTY_PROGRAMME_DIRECTOR_TB = "Deputy Programme Director_TB";
// const DEPUTY_PROGRAMME_DIRECTOR_MDR = "Deputy Programme Director_MDR";
// const PROGRAMME_MANAGER_TB = "Programme Manager_TB";
// const PROGRAMME_MANAGER_AIS = "Programme Manager_AIS";
// const PROGRAMME_MANAGER_MDR = "Programme Manager_MDR";

export const HIS_AND_DIGITAL_LISTERACY_MANAGER =
  "MEAL and Digital Tool Manager";
export const MEAL_AND_DIGITAL_TOOL_OFFICER = "MEAL And Digital Tool Officer";
export const MEAL_ASSOCIATE = "MEAL Associate";
// const PROJECT_OFFICER = "Project Officer";
// const FILED_OFFICER_TB = "Field Officer_TB";
// const FILED_OFFICER_MDR = "Field Officer_MDR";
// const MEDICAL_OFFICER = "Medical Officer";
export const NURSE_FOR_AIS_CLINIC = "Nurse for AIS Clinic";
export const LAB_ASSOCIATE = "Lab Associate";



export const dashboardMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true;
  }
  return false;
};

export const accountMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true;
  }
  return false;
};

export const basicDataMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true;
  }

  return false;
};
export const truenatMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === LAB_ASSOCIATE
  ) {
    return true;
  }

  if (type === READ || type === EXPORT) {
    return true;
  }
};
export const patientMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === LAB_ASSOCIATE
  ) {
    return true;
  }
  if (type === READ || type === EXPORT) {
    return true;
  }
  return false;
};

export const stockMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === LAB_ASSOCIATE
  ) {
    return true;
  }
  if (type === READ || type === EXPORT) {
    return true;
  }
  return false;
};
export const reportMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === LAB_ASSOCIATE
  ) {
    return true;
  }
  if (type === READ || type === EXPORT) {
    return true;
  }
  return false;
};

export const trashMiddleware = (role) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === LAB_ASSOCIATE
  ) {
    return true;
  } else {
    return false;
  }
};
export const handoutMiddleware = (role) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE ||
    role === LAB_ASSOCIATE
  ) {
    return true;
  } else {
    return false;
  }
};