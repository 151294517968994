import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { truelabMTBPlusResults } from "variables/options";
import CustomTable from "components/Table/Table";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { useDispatch, useSelector } from "react-redux";
import { createPlus } from "store/actions";
import { useParams } from "react-router";
import CustomAlert from "components/Alert/CustomAlert";
import { deletePlus } from "store/actions";
import { useEffect } from "react";
import { useCallback } from "react";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { truenatMiddleware } from "utils/middleware";
import { DELETE } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { plusErrors } from "variables/options";
import { getPlusAndRifSolution } from "variables/plusAndRifErrors";
import { truelabMTBChipResults } from "variables/options";

const MTB_PLUS = 3 
const MTB_CHIP = 6 
const TruelabMTBPlus = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { id } = useParams();

  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const [alert, setAlert] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTruelabMTBPlusResult, setSelectedTruelabMTBPlusResult] =
    useState(null);
  const [selectedError, setSelectedError] = useState(null);

  const dispatch = useDispatch();
  const { plus, stock, status, rif } = useSelector((state) => state);

  const stocks = stock.stocks?.filter((stock) => stock.id === MTB_PLUS || stock.id === MTB_CHIP)?.map((stock) => {
    return { value: stock.id, label: stock.name };
  });


  // const defaultStock = stock.stocks.find((stock) => stock.id === MTB_PLUS);

  // const setDefault = useCallback(() => {
  //   setValue("stock", { value: defaultStock?.id, label: defaultStock?.name });
  //   setSelectedItem({ value: defaultStock?.id, label: defaultStock?.name });
  // }, [setValue, defaultStock]);

  // useEffect(() => {
  //   setDefault();
  // }, [setDefault]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      createPlus({
        truenat_id: id,
        stock_id: data.stock.value,
        result: data.result.value,
        error: selectedTruelabMTBPlusResult?.value === "Error" ? data.error.value : null,
        remark: data.remark,
      })
    );
    setLoading(false);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deletePlus(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        label="You won't be able to recover this."
      />
    );
  };

  const formReset = useCallback(() => {
    setSelectedTruelabMTBPlusResult(null);
    setSelectedError(null);
    reset();
    // setDefault();
  }, [reset, setSelectedTruelabMTBPlusResult, setSelectedError, 
    // setDefault
  ]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  let plusResult = plus?.pluses[plus?.pluses.length - 1]?.result;

  return (
    <>
      {alert}
      <Container className="mt-MTB_PLUS" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <hMTB_PLUS>Truelab</hMTB_PLUS>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="stock"
                    label="Equipment"
                    rules={{ required: "Equipment is required!" }}
                    control={control}
                    options={stocks}
                    value={selectedItem}
                    setData={setSelectedItem}
                    placeholder="Equipment"
                    // isDisabled={true}
                  />
                </Col>
              </Row>
              {(selectedTruelabMTBPlusResult?.value === "Invalid" ||
                selectedTruelabMTBPlusResult?.value === "Error") && (
                  <>
                    <hMTB_PLUS
                      style={{
                        color: "#2F7MTB_CHIPBA",
                      }}
                    >
                      SOLUTION
                    </hMTB_PLUS>
                    <p
                      style={{
                        color: "#f00",
                        fontWeight: "bold",
                      }}
                    >
                      {getPlusAndRifSolution(selectedTruelabMTBPlusResult?.value)}
                    </p>
                  </>
                )}
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="result"
                    label="Result"
                    rules={{ required: "Result is required!" }}
                    control={control}
                    options={selectedItem?.value === MTB_CHIP ? truelabMTBChipResults : truelabMTBPlusResults}
                    value={selectedTruelabMTBPlusResult}
                    setData={setSelectedTruelabMTBPlusResult}
                    placeholder="Result"
                  />
                </Col>
              </Row>
              {selectedTruelabMTBPlusResult?.value === "Error" && (
                <Row>
                  <Col md={4}>
                    <CustomSelect
                      id="error"
                      label="Error"
                      rules={{ required: "Error is required!" }}
                      control={control}
                      options={plusErrors}
                      value={selectedError}
                      setData={setSelectedError}
                      placeholder="Error"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <CustomTextArea
                    id="remark"
                    label="Remark"
                    register={{
                      ...register("remark"),
                    }}
                    placeholder="Enter Remark"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
              </Row>
            </CardBody>
            {truenatMiddleware(role, CREATE) && (
              <CardFooter className="text-right">
                {plusResult !== "MTB-Detected (VL)" &&
                  plusResult !== "MTB-Detected (L)" &&
                  plusResult !== "MTB-Detected (M)" &&
                  plusResult !== "MTB-Detected (H)" && 
                  plusResult !== "MTB-Detected" && 
                  plusResult !== "MTB-Not Detected" && (
                    <Button
                      color="primary"
                      size="sm"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Loading" : "Save"}
                    </Button>
                  )}
              </CardFooter>
            )}
          </Card>
        </Form>
        <Card>
          <CardBody>
            <CustomTable
              header={
                <tr>
                  <th className="fixed-cell left" scope="col">
                    ID
                  </th>
                  <th className="fixed-cell left" scope="col">
                    Equipment
                  </th>
                  <th className="fixed-cell left" scope="col">
                    Result
                  </th>
                  <th>Error</th>
                  <th>Remark</th>
                  {truenatMiddleware(role, DELETE) &&
                    rif?.rifs.length === 0 && <th scope="col">Action</th>}
                </tr>
              }
              body={plus.pluses.map((plus, index) => (
                <tr key={plus.id}>
                  <td>{index + 1}</td>
                  <td>{plus.stock?.name}</td>
                  <td>{plus.result}</td>
                  <td>{plus.error}</td>
                  <td>{plus.remark}</td>

                  {truenatMiddleware(role, DELETE) &&
                    rif?.rifs.length === 0 && (
                      <td>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleDelete(plus.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                </tr>
              ))}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default TruelabMTBPlus;
