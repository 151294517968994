import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Card,
} from "reactstrap";
import { patientMiddleware } from "utils/middleware";
import CustomSelect from "components/Inputs/CustomSelect";
import { dones } from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { geneXpertResults } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updatePatient } from "store/actions";
import { UPDATE } from "utils/middleware";


const GeneXpert = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [geneXpertDoneNotDone, setGeneXpertDoneNotDone] = useState(null);
  const [geneXpertResult, setGeneXpertResult] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { patient } = useSelector((state) => state);

  useEffect(() => {
    const { gene_done_or_not_done, gene_examination_date, gene_result } =
      patient.patient;

    setValue(
      "gene_done_or_not_done",
      gene_done_or_not_done
        ? { value: gene_done_or_not_done, label: gene_done_or_not_done }
        : null
    );
    setGeneXpertDoneNotDone(
      gene_done_or_not_done
        ? { value: gene_done_or_not_done, label: gene_done_or_not_done }
        : null
    );
    setValue("gene_examination_date", gene_examination_date);
    setValue(
      "gene_result",
      gene_result ? { value: gene_result, label: gene_result } : null
    );
    setGeneXpertResult(
      gene_result ? { value: gene_result, label: gene_result } : null
    );
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      updatePatient(
        patient.patient.id,
        {
          gene_done_or_not_done: data.gene_done_or_not_done?.value,
          gene_examination_date: geneXpertDoneNotDone?.value === 'Done' ? data.gene_examination_date : null,
          gene_result: geneXpertDoneNotDone?.value === 'Done' ? data.gene_result?.value : null,
        },
        "gene"
      )
    );
    setLoading(false);
  };

  return (
    <Container className="mt-3" fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardBody>
            <CardTitle>
              <h3>Xpert MTB/RIF</h3>
            </CardTitle>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="gene_done_or_not_done"
                  label="Gene Xpert Done or Not Done"
                  control={control}
                  rules={{
                    required: "Gene Xpert Done or Not Done is required!",
                  }}
                  options={dones}
                  value={geneXpertDoneNotDone}
                  setData={setGeneXpertDoneNotDone}
                  placeholder="Gene Xpert Done or Not Done"
                  isRequired={true}
                />
              </Col>
              {geneXpertDoneNotDone?.value === "Done" && (
                <>
                  <Col md={4}>
                    <CustomDateInput
                      id="gene_examination_date"
                      label="GXP Examination Date"
                      register={{
                        ...register("gene_examination_date", {
                          required:
                            geneXpertDoneNotDone?.value === "Done"
                              ? "GXP Examination Date is required!"
                              : false,
                        }),
                      }}
                      placeholder="Select GXP Examination Date"
                      errors={errors}
                      max={currentDate()}
                      disabled={
                        geneXpertDoneNotDone === null ||
                        geneXpertDoneNotDone?.value !== "Done"
                      }
                      isRequired={geneXpertDoneNotDone?.value === "Done"}
                    />
                  </Col>
                  <Col md={4}>
                    <CustomSelect
                      id="gene_result"
                      label="Gene Xpert Result"
                      control={control}
                      rules={{
                        required:
                          geneXpertDoneNotDone?.value === "Done"
                            ? "Gene Xpert Result is required!"
                            : false,
                      }}
                      options={geneXpertResults}
                      value={geneXpertResult}
                      setData={setGeneXpertResult}
                      placeholder="Gene Xpert Result"
                      isRequired={geneXpertDoneNotDone?.value === "Done"}
                      isDisabled={
                        geneXpertDoneNotDone === null ||
                        geneXpertDoneNotDone?.value !== "Done"
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
          </CardBody>
          <CardFooter className="text-right">
          {
              patientMiddleware(role, UPDATE) &&  <Button color="primary" size="sm" type="submit">
              {loading ? "Loading" : "Update"}
            </Button>
             }
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};

export default GeneXpert;
