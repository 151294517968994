import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import styles from "./Login.module.css";
import { connect } from "react-redux";
import { login } from "store/actions";

const Login = ({ status, login }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassowrd] = React.useState(false);

  const handleLogin = async () => {
    await login({ email, password });
  };
  return (
    <>
      <Container className="mt-7">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className={`border-0 mb-0 ${styles.card}`}>
              <CardBody className="px-lg-5 py-lg-5">
                <CardTitle className={styles.title}>
                  Truenat Dashboard
                </CardTitle>
                <Form role="form">
                  <FormGroup className={classnames("mb-3")}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mt-2 ml-4 d-flex">
                    <div>
                      <input
                        onChange={() => {
                          setShowPassowrd(!showPassword);
                        }}
                        checked={showPassword}
                        type="checkbox"
                      />
                    </div>
                    <label className="text-white">show password</label>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className={`my-2 ${styles.button}`}
                      type="button"
                      onClick={handleLogin}
                      disabled={status.loading}
                    >
                      {status.loading ? "Loading" : "Sign in"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
});

export default connect(mapStateToProps, { login })(Login);
