import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { createStock } from "store/actions";

const StockCreate = ({ isOpen, toggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.status)

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(createStock(data))
    setLoading(false);
  };

  useEffect(() => {
    if (success) {
      reset()
    }
  }, [success, reset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Stock"
      onClick={() => {
        toggle(false);
        reset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="Name"
            register={{
              ...register("name", {
                required: "Name is required!",
              }),
            }}
            placeholder="Enter name"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Save"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default StockCreate;
