import React, { useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BackBtn from "utils/backBtn";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getTruenats } from "store/actions";
import { truenatMiddleware } from "utils/middleware";
import { CREATE } from "utils/middleware";
import { getPatient } from "store/actions";

const TruenatList = () => {
  const { patientId } = useParams();
  const router = useHistory();

  const { patient } = useSelector((state) => state.patient);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { status, truenat } = useSelector((state) => state);
  const { loading } = status;
  const { truenats } = truenat;

  useEffect(() => {
    dispatch(getTruenats(patientId));
    dispatch(getPatient(patientId));
  }, [dispatch, patientId]);

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">Truenat List</h3>
                <span>
                  Patient -{" "}
                  {`${patient?.name}  ( ${patient?.presumptive_code} )`}
                </span>
              </Col>
              <Col className="text-right" xs="6">
                {truenatMiddleware(role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push({
                        pathname: `/admin/truenat-create/${patientId}`,
                      })
                    }
                  >
                    New
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  Date Specimen Received
                </th>
                <th scope="col">Action</th>
              </tr>
            }
            body={truenats.map((truenat, index) => (
              <tr key={truenat.id}>
                <td>{index + 1}</td>
                <td>{truenat.specimen_received_date}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push({
                        pathname: `/admin/truenat-detail/${truenat.id}`,
                        state: {
                          patientId,
                          index,
                        }
                      })
                    }
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />
        </Card>
      </Container>
    </>
  );
};

export default TruenatList;
