import {
  CREATE_TRUENAT,
  DELETE_TRUENAT,
  SET_TRUENAT,
  SHOW_TRUENATS,
  UPDATE_TRUENAT,
} from "../type";

const initialState = {
  truenats: [],
  truenat: {},
  createdTruenat: {},
};

const truenat = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TRUENATS:
      return {
        ...state,
        truenats: action.payload,
      };
    case CREATE_TRUENAT:
      return {
        ...state,
        truenats: [action.payload, ...state.truenats],
        createdTruenat: action.payload,
      };
    case DELETE_TRUENAT:
      return {
        ...state,
        truenats: state.truenats.filter(
          (truenat) => truenat.id !== action.payload
        ),
      };
    case SET_TRUENAT:
      return {
        ...state,
        truenat: action.payload,
      };
    case UPDATE_TRUENAT:
      if (JSON.stringify(state.truenat) !== JSON.stringify(action.payload)) {
        let clone = [...state.truenats];
        let index = clone.findIndex(
          (truenat) => truenat.id === action.payload.id
        );
        clone[index] = action.payload;
        return {
          ...state,
          truenats: clone,
          truenat: action.payload,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default truenat;
