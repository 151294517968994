import { CREATE_PREPS, DELETE_PREPS, SHOW_PREPS, UPDATE_PREPS } from "../type";

const initialState = {
  preps: [],
};

const prep = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PREPS:
      return {
        ...state,
        preps: action.payload,
      };
    case CREATE_PREPS:
      return {
        ...state,
        preps: [...state.preps, action.payload],
      };
    case UPDATE_PREPS:
      if (action.payload.id) {
        let clone = [...state.preps];
        let index = clone.findIndex(
          (prep) => prep.id === action.payload.id
        );
        clone[index] = action.payload;
        return {
          ...state,
          preps: clone,
        };
      } else {
        return state;
      }
    case DELETE_PREPS:
      return {
        ...state,
        preps: state.preps.filter((prep) => prep.id !== action.payload),
      };
    default:
      return state;
  }
};

export default prep;
