import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Container,
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import TruenatHead from "./TruenatHead";
import { currentDate } from "utils/currentDate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { useDispatch, useSelector } from "react-redux";
import { getTruenatDataReport } from "store/actions/report";

const TruenatReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const router = useHistory();

  const { truenatReport } = useSelector((state) => state.report);
  const { loading } = useSelector((state) => state.status);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getTruenatDataReport(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };


  if (loading) {
    return <FullScreenLoading />;
  }
  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Truenat data Report</h3>
            </Col>
            <Col className="text-right" xs="6">
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="btn btn-sm bg-primary text-white border-0"
                table="table-to-xls"
                filename="Meal report"
                sheet="Meal report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={4}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                value={endDate}
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>
            <Col md={4}>
              <Button
                className="mt-5"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <Table
          className={classNames("app-table align-items-center table-fixed")}
          responsive
          id="table-to-xls"
        >
          <TruenatHead />
          <tbody className="list">
            <tr>
              <td className="fixed-cell left">{truenatReport?.No}</td>
              <td className="fixed-cell left">{truenatReport?.organization}</td>
              <td>{truenatReport?.region}</td>
              <td>{truenatReport?.township}</td>
              <td>{truenatReport?.fy}</td>
              <td>{truenatReport?.quarter}</td>
              <td>{truenatReport?._1}</td>
              <td>{truenatReport?._2}</td>
              <td>{truenatReport?._3?.less_than_female}</td>
              <td>{truenatReport?._3?.greater_than_female}</td>
              <td>{truenatReport?._3?.less_than_male}</td>
              <td>{truenatReport?._3?.greater_than_male}</td>
              <td>{truenatReport?._4}</td>
              <td>{truenatReport?._5}</td>
              <td>{truenatReport?._6?.less_than_female}</td>
              <td>{truenatReport?._6?.greater_than_female}</td>
              <td>{truenatReport?._6?.less_than_male}</td>
              <td>{truenatReport?._6?.greater_than_male}</td>
              <td>{truenatReport?._7}</td>
              <td>{truenatReport?._8}</td>
              <td>{truenatReport?._9}</td>
              <td>{truenatReport?._10?.truenat}</td>
              <td>{truenatReport?._10?.smear}</td>
              <td>{truenatReport?._10?.gxp}</td>
              <td>{truenatReport?._11?.smear_positive}</td>
              <td>{truenatReport?._11?.smear_negative}</td>
              <td>{truenatReport?._11?.gxp_positive}</td>
              <td>{truenatReport?._11?.gxp_negative}</td>
              <td>{truenatReport?._12?.truenat}</td>
              <td>{truenatReport?._12?.gxp}</td>
              <td>{truenatReport?._12?.gxp_positive}</td>
              <td>{truenatReport?._12?.gxp_negative}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

export default TruenatReport;
