import styles from "../Truenat.module.css";
import classnames from "classnames";

const PatientExportHead = () => {
  return (
    <thead className="thead-light">
      <tr>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          No
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          Date
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          OPD No
        </th>
        <th
          className={["fixed-cell left", styles.skyblue].join(" ")}
          rowSpan={3}
        >
          Name
        </th>

        <th className={[styles.skyblue, styles.width].join(" ")} rowSpan={3}>
          Age
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")} rowSpan={3}>
          Sex
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          Lab Code
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          Pretreatment Pack
        </th>

        <th className={[styles.skyblue, styles.width].join(" ")} colSpan={4}>
          Universal Cartridge{" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>
              where result VL,L,M,H in PLUS count
            </span>
          </i>
        </th>

        <th className={[styles.skyblue, styles.width].join(" ")} colSpan={9}>
          MTB (Plus){" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>
              where result MTB-Detected, RR (+) in RIF count
            </span>
          </i>
        </th>
        <th className={[styles.skyblue, styles.width].join(" ")} colSpan={8}>
          MTB (Riff){" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}>
              where result MTB-Detected, RR (+) in RIF count
            </span>
          </i>
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          Remark
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          Machine Error
        </th>
        <th className={[styles.skyblue].join(" ")} rowSpan={3}>
          Machine Error Remark
        </th>

        <th className={[styles.yellow].join(" ")} rowSpan={3}>
          Specimen received date
        </th>
        <th className={[styles.yellow].join(" ")} rowSpan={3}>
          Pretreatment Pack
        </th>

        <th className={[styles.yellow, styles.width].join(" ")} colSpan={4}>
          Universal Cartridge{" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}></span>
          </i>
        </th>

        <th className={[styles.yellow, styles.width].join(" ")} colSpan={9}>
          MTB (Plus){" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}></span>
          </i>
        </th>
        <th className={[styles.yellow, styles.width].join(" ")} colSpan={8}>
          MTB (Riff){" "}
          <i className={classnames("fa fa-question-circle", styles.tooltip)}>
            <span className={classnames(styles.tooltiptext)}></span>
          </i>
        </th>
        <th className={[styles.yellow].join(" ")} rowSpan={3}>
          Remark
        </th>
        <th className={[styles.yellow].join(" ")} rowSpan={3}>
          Machine Error
        </th>
        <th className={[styles.yellow].join(" ")} rowSpan={3}>
          Machine Error Remark
        </th>
      </tr>
      <tr>
        <th className={styles.skyblue} colSpan={2}>
          DNA Isolated
        </th>
        <th className={styles.skyblue} colSpan={2}>
          Isolation Failure
        </th>

        <th className={styles.skyblue} colSpan={2}>
          Invalid
        </th>
        <th className={styles.skyblue} colSpan={2}>
          Error No.
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          Negative
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          MTB Detected, (Very Low)
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          MTB Detected, (Low)
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          MTB Detected, (Medium)
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          MTB Detected, (High)
        </th>
        <th className={styles.skyblue} colSpan={2}>
          Invalid
        </th>
        <th className={styles.skyblue} colSpan={2}>
          Error No.
        </th>
        <th className={styles.skyblue} colSpan={2}>
          MTB Detected RR (Indeterminate)
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          MTB Detected, RR(-)
        </th>
        <th className={styles.skyblue} rowSpan={2}>
          MTB Detected, RR(+)
        </th>

        <th className={styles.yellow} colSpan={2}>
          DNA Isolated
        </th>
        <th className={styles.yellow} colSpan={2}>
          Isolation Failure
        </th>
        <th className={styles.yellow} colSpan={2}>
          Invalid
        </th>
        <th className={styles.yellow} colSpan={2}>
          Error No.
        </th>
        <th className={styles.yellow} rowSpan={2}>
          Negative
        </th>
        <th className={styles.yellow} rowSpan={2}>
          MTB Detected, (Very Low)
        </th>
        <th className={styles.yellow} rowSpan={2}>
          MTB Detected, (Low)
        </th>
        <th className={styles.yellow} rowSpan={2}>
          MTB Detected, (Medium)
        </th>
        <th className={styles.yellow} rowSpan={2}>
          MTB Detected, (High)
        </th>
        <th className={styles.yellow} colSpan={2}>
          Invalid
        </th>
        <th className={styles.yellow} colSpan={2}>
          Error No.
        </th>
        <th className={styles.yellow} colSpan={2}>
          MTB Detected RR (Indeterminate)
        </th>
        <th className={styles.yellow} rowSpan={2}>
          MTB Detected, RR(-)
        </th>
        <th className={styles.yellow} rowSpan={2}>
          MTB Detected, RR(+)
        </th>
      </tr>
      <tr>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>
        <th className={styles.skyblue}> 1st</th>
        <th className={styles.skyblue}>2nd</th>

        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
        <th className={styles.yellow}> 1st</th>
        <th className={styles.yellow}>2nd</th>
      </tr>
    </thead>
  );
};
export default PatientExportHead;
