import CustomTextArea from 'components/Inputs/CustomTextArea'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Form,
    Row,
    Col,
    Container,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
} from 'reactstrap'
import { updateTruenat } from 'store/actions'
import { truenatMiddleware, UPDATE } from "utils/middleware";

const TruenatRemark = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm()

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
  const { role } = user;
    const { truenat } = useSelector((state) => state);

    useEffect(() => {
        const { overall_remark } = truenat.truenat

        setValue('overall_remark', overall_remark)
    }, [setValue, truenat.truenat])


    const onSubmit = async (data) => {
        setLoading(true)
        await dispatch(
            updateTruenat(truenat.truenat.id, {
                overall_remark: data.overall_remark
            }, 'overall-remark')
        )
        setLoading(false)
    }

    return (
        <Container className="mt-3" fluid>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader className="border-0">
                        <h3>Truenat Remark</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <CustomTextArea
                                    id="overall_remark"
                                    label="Remark"
                                    register={{
                                        ...register('overall_remark'),
                                    }}
                                    placeholder="Enter Remark"
                                    errors={errors}
                                    isRequired={false}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-right">
                        {
                            truenatMiddleware(role, UPDATE) && <Button
                            color="primary"
                            size="sm"
                            disabled={loading}
                            type="submit"
                        >
                            {loading ? 'Loading' : 'Update'}
                        </Button>
                        }
                    </CardFooter>
                </Card>
            </Form>
        </Container>
    )
}

export default TruenatRemark