const E_1_2 =
    "Please start afresh by processing remainder of sample in lysis buffer and load into new cartridge.";
const E_3 =
    "Ensure sample is liquefied and pipettable. Repeat extraction with new cartridge/request for new sample";
const E_6 = "Ensure cartirdge is loaded properly in correct orientation";
const E_9_10_11_12 = "Contact Molbio support";

export const getSolution = (error) => {
    switch (error) {
        case 'E1':
        case 'E2':
            return E_1_2;
        case 'E3':
            return E_3;
        case 'E6':
            return E_6;
        case 'E9':
        case 'E10':
        case 'E11':
        case 'E12':
            return E_9_10_11_12;
        default:
            break;
    }
}