import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Card, CardHeader } from "reactstrap";
import BackBtn from "utils/backBtn";
import PatientInfo from "./detailComponent/PatientInfo";
import Smear from "./detailComponent/Smear";
import GeneXpert from "./detailComponent/Xpert";
import Remark from "./detailComponent/Remark";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPatient } from "store/actions";
import { getTownships } from "store/actions";
import { getTruenats } from "store/actions";

const PatientDetail = () => {
  const { id } = useParams();
  const router = useHistory();

  const [page, setPage] = useState("Patient_Info");
  const { patient } = useSelector((state) => state.patient);
  const { truenats } = useSelector((state) => state.truenat);

  const handleShowPage = () => {
    switch (page) {
      case "Patient_Info":
        return <PatientInfo />;
      case "Smear_MicroScopy":
        return <Smear />;
      case "Xpert":
        return <GeneXpert />;
      case "Remark":
        return <Remark />;
      default:
        return <PatientInfo />;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatient(id));
    dispatch(getTruenats(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);


  const getColor = (value, pageName) => {
    if (page === pageName) {
      return "primary";
    }
    if (!value) {
      return "danger";
    }
    return "success";
  };

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row className="mb-2">
            <Col>
              <Button
                color={page === "Patient_Info" ? "primary" : "success"}
                size="lg"
                onClick={() => setPage("Patient_Info")}
              >
                PATIENT INFO
              </Button>
              <Button
                color={getColor(
                  patient?.smear_done_or_not_done,
                  "Smear_MicroScopy"
                )}
                size="lg"
                onClick={() => setPage("Smear_MicroScopy")}
              >
                Smear MicroScopy
              </Button>
              <Button
                color={getColor(patient?.gene_done_or_not_done, "Xpert")}
                size="lg"
                onClick={() => setPage("Xpert")}
              >
                Xpert MTB/RIF
              </Button>
              <Button
                color={truenats?.length > 0 ? "success" : "danger"}
                size="lg"
                onClick={() => router.push({
                  pathname: `/admin/truenat-list/${id}`, 
                  state: patient
                })}
              >
                Truenat
              </Button>
              <Button
                color={getColor(patient?.remark, "Remark")}
                size="lg"
                onClick={() => setPage("Remark")}
              >
                Remark
              </Button>
            </Col>
          </Row>
        </CardHeader>
        {handleShowPage()}
      </Card>
    </Container>
  );
};

export default PatientDetail;
