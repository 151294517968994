const error =
    "Repeat the run using a fresh chip and re-load*** the elute by pressing Repeat button.";
const invalid =
    "Re-run the same slute using another chip. If invalid repeats, process the sample again and run slute using another chip.";

export const getPlusAndRifSolution = (value) => {
    if (value === "Invalid") {
        return invalid;
    }
    if (value === "Error") {
        return error;
    }
};
