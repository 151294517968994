import { call } from "../../services/api";
import { PATIENT_EXPORT, SET_LOADING, STOCK_REPORT, TRUENAT_REPORT } from "../type";

export const getStockReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call(
        "get",
        `stock-report?${new URLSearchParams(query).toString()}`
      );

      const result = response.data;
      dispatch({
        type: STOCK_REPORT,
        payload: result,
      });
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const getTruenatDataReport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call(
        "get",
        `MEAL-report?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;
      dispatch({
        type: TRUENAT_REPORT,
        payload: result,
      });
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};

export const getPatientExport = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call(
        "get",
        `patient-export-ui?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;
      dispatch({
        type: PATIENT_EXPORT,
        payload: result,
      });
    } catch (_) { }
    dispatch({ type: SET_LOADING });
  };
};
