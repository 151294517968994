import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import { currentDate } from "utils/currentDate";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateTruenat } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { truenatMiddleware, UPDATE, DELETE } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";
import { deleteTruenat } from "store/actions";
import { useHistory } from "react-router";

const Specimen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  const [alert, setAlert] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { truenat, prep, status } = useSelector((state) => state);

  useEffect(() => {
    const { specimen_received_date } = truenat.truenat;

    setValue("specimen_received_date", specimen_received_date);
  }, [setValue, truenat.truenat]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      updateTruenat(
        truenat.truenat.id,
        {
          specimen_received_date: data.specimen_received_date,
        },
        "truenats"
      )
    );
    setLoading(false);
  };

  const handleDelete = (id, patientId) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deleteTruenat(id));
          setAlert(null);
          router.replace(`/admin/truenat-list/${patientId}`)
        }}
        onCancel={() => setAlert(null)}
        label="You won't be able to recover this."
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Specimen</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="specimen_received_date"
                    label="Specimen Received Date"
                    register={{
                      ...register("specimen_received_date", {
                        required: "Specimen Received Date is required!",
                      }),
                    }}
                    placeholder="Select Specimen Received Date"
                    errors={errors}
                    isRequired={true}
                    max={currentDate()}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              {truenatMiddleware(role, DELETE) && prep.preps.length === 0 && (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => handleDelete(truenat.truenat.id, truenat.truenat.patient.id)}
                >
                  Delete
                </Button>
              )}
              {truenatMiddleware(role, UPDATE) && (
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
              )}
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default Specimen;
