import { call } from "../../services/api";
import {
  CREATE_PREPS,
  DELETE_PREPS,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_PREPS,
  UPDATE_PREPS
} from "../type";
import { NotificationManager } from "react-notifications";

export const getPreps = (truenatId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("get", `preps/${truenatId}`);
      const result = response.data;

      dispatch({
        type: SHOW_PREPS,
        payload: result,
      });
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};

export const createPrep = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "preps", data);
      const result = response.data;

      dispatch({
        type: CREATE_PREPS,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Trueprep has been created successfully!");
    } catch (_) {}
  };
};

export const updatePrepRemark = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", `preps/${id}?_method=PUT`, data);
      const result = response.data;

      dispatch({
        type: UPDATE_PREPS,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success("Trueprep remark has been updated successfully!");
    } catch (_) {}
  };
};
export const deletePrep = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      await call("delete", `preps/${id}`);

      dispatch({ type: DELETE_PREPS, payload: id });
      NotificationManager.success("Trueprep has been deleted successfully!");
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};
