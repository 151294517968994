import { CREATE_RIFS, DELETE_RIFS, SHOW_RIFS } from '../type'
  
  const initialState = {
    rifs: [],
  }
  
  const rif = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_RIFS:
        return {
          ...state,
          rifs: action.payload,
        }
      case CREATE_RIFS:
        return {
          ...state,
          rifs: [...state.rifs, action.payload],
        }
      case DELETE_RIFS:
        return {
          ...state,
          rifs: state.rifs.filter(
            (rif) => rif.id !== action.payload,
          ),
        }
      default:
        return state
    }
  }
  
  export default rif
  